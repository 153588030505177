<template>
  <div class="pt-3 pl-5 pr-5 overflow-y-hidden">
    <div class="row w-full justify-between flex" style="align-items: center">
      <h1 class="title">Frameworks</h1>
      <div class="flex items-center">
        <button
          class="btnprimary mr-2"
          @click="showAddPopup()"
        >
          Add
        </button>
        <div class="flex flex-row-reverse items-center">
            <button class="flex items-center" @click="filterOpen" :class="frameworksDataArray.length == 0?'btndisabled pointer-events-none':'filterButton h-8'">
                Filters
                <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
        </div>
      </div>
    </div>
    <div class="mt-3 text-xs">
      The Global Risks Report 2022, 17th Edition, is published by the World
      Economic Forum. The information in this report, or on which this report is
      based, has been obtained from sources that the authors believe to be
      reliable and accurate. However, it has not been independently verified and
      no representation or warranty, express or implied, is made as to the
      accuracy or completeness of any information obtained from third parties.
    </div>
    <div class="mt-3">
      <newTable
        :tableRowData="frameworksDataArray"
        :headersData="headers"
        :tableProps="tableProps"
        :openPopup="false"
        :navigate="true"
        :loadData="dataLoading"
      ></newTable>
      <div v-if="toolTipActive">
        <tool-tip :tipText="toolTipText"></tool-tip>
      </div>
    </div>
    <popup v-if="showFrameworkPopUp">
      <template v-slot:header>
        <div class="flex items-center justify-between py-4 px-2 rounded">
            <h1 class="pl-2 w-60">Framework</h1>
            <button class="float-right text-3xl" @click="onCancelbtn('close')">
                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
            </button>
        </div>
    </template>
    <fieldset>
      <div class="popup-priority">
        <div class="statuscontainer popupbodycontainer">
            <div class="row flex flex-row w-full upperspacing">
                <div class="w-full">
                    <label class="controllabel">Name</label>
                    <input
                        v-model.trim="frameworkObj.name"
                        placeholder="Name"
                        class="inputboxstyling"
                        @input="v$.frameworkObj.name.$touch()"
                        :class="{
                            'is-invalid': (v$.frameworkObj.name.$error || checkFrameworkName),
                        }"
                        />
                        <div class="text-red-500" v-if="v$.frameworkObj.name.$error || checkFrameworkName">
                        <div v-if="v$.frameworkObj.name.required.$invalid" class="error-text text-xs">Required</div>
                        <div v-else-if="checkFrameworkName" class="invalid-feedback text-xs">Framework Name already exists.</div>
                        <div v-else-if="v$.frameworkObj.name.required && v$.frameworkObj.name.restrictNameCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                    </div>
                </div>
            </div>
            <div class="row flex flex-row w-full upperspacing">
                <div class="w-full">
                    <label class="controllabel">Description</label>
                    <textarea
                        v-model.trim="frameworkObj.description"
                        placeholder="Description"
                        class="inputboxstyling"
                        @input="v$.frameworkObj.description.$touch()"
                        :class="{
                            'is-invalid': v$.frameworkObj.description.$error,
                        }"
                    ></textarea>
                    <div class="text-red-500" v-if="v$.frameworkObj.description.$error">
                        <div v-if="v$.frameworkObj.description.required.$invalid" class="error-text text-xs">Required</div>
                        <div v-else-if="v$.frameworkObj.description.required && v$.frameworkObj.description.restrictDescriptionCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                    </div>
                </div>
            </div>
            <div class="row w-full upperspacing">
                <label class="controllabel">Logo</label>
                <div v-if="(frameworkObj.imageUrl == '' && !frameworkObj._id) || (frameworkObj.imageUrl == '' && frameworkObj._id) " :class="{'is-invalid borderRed': v$.fileName.$error,}" class="border w-full rounded py-2 border-lightgrey">
                    <div class="flex justify-center">
                        <div>
                            <div class="flex justify-center">
                                <label :htmlFor="frameworkObj" class="mt-2">
                                    <img src="@/assets/addImage.svg" alt="" class="w-20 h-20 cursor-pointer" />
                                </label>
                                <label :htmlFor="frameworkObj">
                                    <input type="file" :id="frameworkObj" style="display: none" ref="fileInput" @change.stop="updateFileName('fileInput','add')"/>
                                </label>
                            </div>
                            <div class="flex justify-center items-center mt-2">
                                <div v-if="fileName!=''"><span class="text-xs">Selected File : </span><span class="text-sm font-bold">{{fileName}}</span>	</div>
                                <div v-else-if="fileName == ''" class="text-xs">{{'Click on the icon for uploading a file'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="frameworkObj._id && frameworkObj.imageUrl !=''" class="border w-full rounded py-2  border-lightgrey">
                    <div class="flex justify-center p-2 items-center">
                        <label :htmlFor="frameworkObj" class="mt-2">
                            <img :src="frameworkObj.imageUrl" alt="" class="w-20 h-20 cursor-pointer" />
                        </label>
                        <label :htmlFor="frameworkObj">
                            <input type="file" :id="frameworkObj" style="display: none" ref="fileInput" @change.stop="updateFileName('fileInput','edit')"/>
                        </label>
                    </div>
                    <div class="flex justify-center items-center mt-2">
                        <div v-if="fileName!=''"><span class="text-xs">Selected File : </span><span class="text-sm font-bold">{{fileName}}</span></div>
                    </div>
                </div>
                <div v-if="v$.fileName.$error" class="text-red-500">
                    <div class="error-text text-xs" v-if="v$.fileName.required.$invalid">Required</div>
                </div>
            </div>
        </div>
        <div class="buttonposition flex justify-center items-center mb-4 mx-0">
            <button :disabled="clickOnButton" :class="JSON.stringify(frameworkObj) == JSON.stringify(this.dummyFramework) && fileName == '' ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5"  @click="onCancelbtn('cancel')">Cancel</button>
            <button :disabled="clickOnButton" :class="JSON.stringify(frameworkObj) == JSON.stringify(this.dummyFramework) && fileName == '' ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5" v-if="!frameworkObj._id" @click="addFramework()">Save</button>
            <button :disabled="clickOnButton" :class="JSON.stringify(frameworkObj) == JSON.stringify(this.dummyFramework) && fileName == '' ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5" @click="editFramework()" v-else >Update</button>
        </div>
      </div>
    </fieldset>
    </popup>
    <!-- <spinner v-if="spinnerLoading" /> -->
  </div>
</template>
<style scoped>
.controllabel {
  font-size: 12px;
}
.labelMarginTop {
  margin-top: 8px;
}
.empty tr td {
  border-bottom: 1px solid #e9e9e9 !important;
}
.placeholder-styles {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
}
.uploadedFileNameStyles{
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	color: #4D4D4D;
}
.name{
    width: 30%;
}
.description{
    width: 70%;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import newTable from "@/components/newTable.vue";
import popup from "@/components/sidePopup.vue";
import { emitter, toast } from "@/main";
// import Multiselect from '@vueform/multiselect';
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Multiselect from "vue-multiselect";
import toolTip from "@/components/toolTip.vue";
import { v4 as uuidv4 } from 'uuid';
import { helpers } from '@vuelidate/validators';
const restrictNameCharacters = helpers.regex(/^.{0,20}$/);
const restrictDescriptionCharacters = helpers.regex(/^.{0,300}$/);
// import spinner from '@/components/spinner.vue';

export default defineComponent({
  data(): any {
    return {
      v$: useVuelidate(),
      headers: ["name", "description"],
      showFrameworkPopUp: false,
    //   spinnerLoading: false,
      frameworksDataArray: [],
      tableProps: {
        headerNames: [
          { key: "description", value: "description" },
          { key: "name", value: "Name" },
        ],

        headerWidth: [
          { key: "description", value: 'w-3/4' },
          { key: "name", value: 'w-1/3' },
        ],
      },
      showFilters: false,
      frameworksArray: [],
      dummyList:[],
      toolTipActive: false,
      fileName: '',
      toolTipText: "",
      clickOnButton: false,
      perviousFrameworkNames: [],
      presentImageUrl: '',
      presentFile: '',
      dummyFramework: {},
      filteredFrameworkNames: [],
      dataLoading: true,
      frameworkObj: {
        name: "",
        description: "",
        imageUrl: "",
      },
    };
  },
  components: {
    newTable,
    popup,
    Multiselect,
    toolTip,
    // spinner
  },
  validations(){
    return {
        frameworkObj: {
            name: {required, restrictNameCharacters},
            description: {required, restrictDescriptionCharacters},
        },
        fileName:{required}
    }
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
    checkFrameworkName: function (): any {
        let trimmedSystemName:any;

        // if(Object.keys(this.frameworkObj).includes('controlFrameWork')){
        //     trimmedSystemName = this.frameworkObj.controlFrameWork.trim().toLowerCase();
        // }else{
            trimmedSystemName = this.frameworkObj.name.replace(/\s+/g, '').trim().toLowerCase();
        // }

        if(this.perviousFrameworkNames == null || this.perviousFrameworkNames == undefined || this.perviousFrameworkNames.length == 0){
            return false
        }
        if(this.frameworkObj._id){
            let a = false;
            a = this.filteredFrameworkNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedSystemName);
            return a
        }else{
            let a = false;
            a = this.perviousFrameworkNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedSystemName);
            return a
        }
    }
  },
  methods: {
    filterOpen() {
      this.showFilters = !this.showFilters;
      if (this.showFilters == true) {
        this.dummyList = this.frameworksArray;
      } else {
        this.frameworksArray = this.dummyList;
      }
      emitter.emit("visible", this.showFilters);
    },
    showAddPopup() {
        this.showFrameworkPopUp = true;
        this.frameworkObj = {
            name: "",
            description: "",
            imageUrl: "",
        };
        this.dummyFramework = {...this.frameworkObj}
    },
    showEditPopup(data: any) {
        let findObj:any = this.frameworksArray.find((obj:any)=>{return obj._id == data._id})

        if(findObj != undefined){
            this.frameworkObj = {...findObj}
        }
        
        if(Object.keys(this.frameworkObj).includes('controlFrameWork')){
            this.frameworkObj.name = this.frameworkObj.controlFrameWork
        }

        if(!Object.keys(this.frameworkObj).includes('imageUrl')){
            this.frameworkObj.imageUrl = '';
        }

        if(!Object.keys(this.frameworkObj).includes('description')){
            this.frameworkObj.description = '';
        }

        

        this.filteredFrameworkNames = this.perviousFrameworkNames.filter((name:any)=>{
            return name != this.frameworkObj.name
        })

        this.presentImageUrl = this.frameworkObj.imageUrl



        this.dummyFramework = {...this.frameworkObj};
        this.showFrameworkPopUp = true;
    },
    onCancelbtn(prop:any){
        this.v$.$reset();
        if(prop == 'close'){
            this.showFrameworkPopUp  = false;
            this.fileName = '';
        }

        if(this.fileName != ''){
            this.fileName = ''
        }

        this.resetFileUploads()
        this.frameworkObj = {...this.dummyFramework};
    },
    async addFramework() {
        this.v$.$touch();
        if (!this.v$.frameworkObj.$invalid && !this.v$.fileName.$invalid && this.checkFrameworkName == false) {
            this.clickOnButton = true;
            let payload:any = {
                "name":this.frameworkObj.name,
                "description":this.frameworkObj.description,
                "imageUrl":"",
            }
            this.dataLoading = true
            await this.uploadToS3AndGetLink(this.presentFile);
            payload.imageUrl = this.presentImageUrl
            console.log("presentImageUrl", this.presentImageUrl);
            if(payload.imageUrl != ''){
                await this.addNewFramework(payload);
            }
            console.log("payload", payload)
            this.dataLoading = false;
        } else {
            toast.error(`Error`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        };
    },
    async addNewFramework(payload:any){
        try{
        await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/framework/create`, [payload]).then(async (response: any) => {
            this.presentFile = '';
            this.presentImageUrl  = '';
            await this.getAllFrameworks();
            await this.getAllFrameworkRoot()
            this.onCancelbtn('close')
            toast.info('Saved', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });	
        })
        .catch((error: any) => {
            toast.error('Error', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        });

        } catch(err){
            toast.error('Error', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        } finally {
            this.clickOnButton = false
        }
    },
    async editFramework(){
        if(this.frameworkObj.imageUrl !== ''){
            this.v$.frameworkObj.$touch();
        }else{
            this.v$.$touch();
        }
        
        if (!this.v$.frameworkObj.$invalid && this.checkFrameworkName == false) {
            this.clickOnButton = true 
            let presentId = this.frameworkObj._id;
            let payload:any = {
                "name":this.frameworkObj.name,
                "description":this.frameworkObj.description,
                "imageUrl": this.frameworkObj.imageUrl
            }

            if(Object.keys(this.frameworkObj).includes('imageChanged')){
                await this.uploadToS3AndGetLink(this.presentFile);

                payload.imageUrl = this.presentImageUrl;
            }

            await this.updatePresentFramework(payload,presentId);
            
        }else{
            console.log('this.v$.system.$invalid',!this.v$.frameworkObj.$invalid)
            console.log('this.checkFrameworkName == false',this.checkFrameworkName == false)
        }

    },
    async updatePresentFramework(payload:any, presentId:any){
        try{
            await this.$http
            .post(`${process.env.VUE_APP_ADMIN_API_URL}/root/framework/${presentId}/update`, payload)
            .then(async (response: any) => {
                this.presentFile = '';
                this.presentImageUrl  = '';
                this.onCancelbtn('close')
                await this.getAllFrameworks();
                await this.getAllFrameworkRoot()
                toast.info('Updated', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });	
            })
            .catch((error: any) => {
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            });
        }catch(err){
            toast.error('Error', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        } finally{
            this.clickOnButton = false
        }
        },
    async uploadToS3AndGetLink(file: any) {
        // this.spinnerLoading = true;
        const bufferData = await this.uploadEncodedDataFormat(file);
        let fileExtension = file.name.split('.').pop();
        let id= uuidv4();

        let payload = { fileData: bufferData, name: `${id}.${fileExtension}`};
        try {
            await this.$http.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload).then((response: any) => {
                if (response.status == 200) {
                    console.log("response", response.data);
                    this.presentImageUrl = response.data;
                    // this.spinnerLoading = false;
                } else {
                    console.log('response status', response.status);
                }
            });
        } catch (err) {
            console.error(err);
        }
    },
    async uploadEncodedDataFormat(data: any) {
        return new Promise((resolve) => {
            const reader: any = new FileReader();
            reader.onload = function () {
                resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
            };
            reader.readAsDataURL(data);
        });
    },
    updateFileName(fileInput: any, type: any) {
        let file = [];
        this.fileName = '';
        
        file = this.$refs[fileInput].files[0];

        console.log('myfile',file)
        
        if (file != undefined) {
            this.imgCheck(file, type);
        }
    },
    imgCheck(file: any, type: any) {
        if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
            let size = Number((file.size / 1048576).toFixed(2));
            if (size > 80) {
                toast.error('Image should be atmost 80 MB', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
                this.resetFileUploads()
            } 
            else {
                this.fileName = file.name;
                this.presentFile = file;
                // if(type == 'edit'){
                    this.frameworkObj.imageChanged = true
                // }
            }
        } else {
            toast.error('Supported only jpg, jpeg, png', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
            this.resetFileUploads()
        }
    },
    resetFileUploads(){
        if(this.$refs.fileInput.value){
            this.$refs.fileInput.value='';
        }
    },
    async getAllFrameworks() {
        this.frameworksDataArray = [];
        this.dataLoading = true;
        await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/framework/getall`).then((res: any) => {
            console.log('sucesssss',res.data)
            this.frameworksArray = [...res.data]
            this.frameworksArray.map((obj: any) => {
                let newObj = {
                    _id: obj._id,
                    name: Object.keys(obj).includes('controlFrameWork') ? obj.controlFrameWork : obj.name,
                    description: obj.description != undefined ? obj.description : "N/A",
                };
                this.frameworksDataArray.push({...newObj});
                this.dataLoading = false;
            });
        })
    },
    async getAllFrameworkRoot(){
        this.frameworksArray.map((obj:any)=>{
            this.perviousFrameworkNames.push(Object.keys(obj).includes('controlFrameWork') ? obj.controlFrameWork : obj.name)
        })
    }
  },
  async created() {
    this.dataLoading = true;
    await this.getAllFrameworks();
    await this.getAllFrameworkRoot()
    this.dataLoading = false;
  },
  async mounted() {
    this.dummyList = [...this.frameworksArray];
    emitter.off("dataTransfering");
    emitter.on("dataTransfering", (isData: any) => {
      this.showEditPopup(isData);
    });
    emitter.off("activiteToolTip");
    emitter.on("activiteToolTip", (data: any) => {
      this.toolTipActive = true;
      this.toolTipText = data.tipText;
    });

    emitter.off("deActiviteToolTip");
    emitter.on("deActiviteToolTip", (data: any) => {
      this.toolTipActive = false;
      this.toolTipText = "";
    });
  },
});
</script>

