<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1 v-if="activitiesObj != undefined || activitiesObj != null">{{activitiesObj.name}}</h1>
                <h1 v-else>Activity</h1>
            </div>
            <div class="flex items-center">
                <!-- <button v-if="selectedTabIndex == 0" @click="customPopupPopup" class="btnprimary mr-3 flex items-center">Add</button> -->
                
                <button
                    v-if="selectedTabIndex == 1"
                    class="flex"
                    :class="activeControlsListDisplay.length == 0 ? 'btndisabled pointer-events-none':'filterButton'"
                    @click="filtersOpen()"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
                <button
                    v-if="selectedTabIndex == 2"
                    class="flex "
                    :class="allControlsListDisplay.length == 0 ? 'btndisabled pointer-events-none':'filterButton'"
                    @click="filtersOpen()"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>

        <div class="mt-3">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>

        <div class="mt-5 bg-white border border-lightgrey relative rounded">
            <div class="px-5 pb-5 pt-5 w-full h-full">
                <div v-if="screenReduced">
                    <div class="group-buttons" v-if="isSmallScreen">
                        <!-- Group navigation buttons for smaller screens -->
                        <div class="absolute left-0 top-5 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                            <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                        </div>
                        <div class="absolute right-0 top-5 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                            <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                        </div>
                    </div>
                    <div v-else>
                        <!-- Scroll buttons for larger screens -->
                        <div class="absolute left-0 top-5 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                            <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                        </div>
                        <div class="absolute right-0 top-5 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                            <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                        </div>
                    </div>
                </div>
            <div>
                <div>
                    <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                        <div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="progressbarB rounded h-1"></div>
            </div>

            <div class="w-full">
                <div v-if="selectedTabIndex == 0">
                    <div class="py-2  noScrollbar tabStructure1 overflow-y-auto">
                        <div class="formcontainer flex flex-col">
                            <div class="row flex flex-row w-full gap-3">
                                <div class=" w-1/2">
                                    <label class="controllabel text-xs">Activity Name</label>
                                    <input class="inputboxstyling mt-2" placeholder="Activity Name"  v-model.trim="activitiesObj.name"  @input="v$.activitiesObj.name.$touch()" :class="{'is-invalid': v$.activitiesObj.name.$error}" />
                                    <div v-if="v$.activitiesObj.name.$error " class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.activitiesObj.name.required.$invalid ">Required</div>
                                        <div v-else-if="v$.activitiesObj.name.required && v$.activitiesObj.name.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                                <div class="w-1/2">
                                    <label class="controllabel text-xs">Criticality</label>
                                    <select
                                        class=""
                                        v-model="activitiesObj.criticality"
                                        :class="{
                                            'is-invalid': v$.activitiesObj.criticality.$error,'inputboxstylingForSelectAtPopup': activitiesObj.criticality === '','stylingForInputboxstylingForSelectAtPopup': activitiesObj.criticality !== ''
                                        }"
                                    >
                                        <option value="" selected hidden>Please select one</option>
                                        <option class="optionColors" v-for="option in criticalityoptions" :key="option.id" :value="option.id">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                    <div v-if="v$.activitiesObj.criticality.$error " class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.activitiesObj.criticality.required.$invalid ">Required</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row flex flex-row upperspacing w-full gap-3">
                                <div class="w-1/2">
                                    <label class="controllabel text-xs">Department</label>
                                    <multiselect
                                        v-model="activitiesObj.departments"
                                        :options="teamsOptions" 
                                        :showNoOptions="false"
                                        @search-change="asyncdepartmentDisplayFind"
                                        :multiple="false"
                                        label="label"
                                        track-by="value"    
                                        :searchable="true"  
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :hide-selected="true"
                                        placeholder="Department"
                                        open-direction="bottom"
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed': v$.activitiesObj.departments.$error,
                                            'has-options': teamsOptions && teamsOptions.length > 0,
                                            'no-options': !teamsOptions.length,
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No departments Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.activitiesObj.departments.$error " class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.activitiesObj.departments.required.$invalid ">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="upperspacing w-full">
                                    <label class="controllabel text-xs">Description</label>
                                    <textarea
                                        class="inputboxstyling"
                                        placeholder="Description"
                                        v-model.trim="activitiesObj.description"
                                            @input="v$.activitiesObj.description.$touch()"
                                        :class="{
                                            'is-invalid': v$.activitiesObj.description.$error
                                        }"
                                    />
                                    <div v-if="v$.activitiesObj.description.$error " class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.activitiesObj.description.required.$invalid ">Required</div>
                                        <div v-else-if="v$.activitiesObj.description.required && v$.activitiesObj.description.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                            </div>
                        </div>
                        
                        <!-- <div v-if="editPopup == false" class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                            <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Reset</button>
                            <button class="" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickSaveBtn(activitiesObj)">Save</button>
                        </div>
                        <div v-else class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                            <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Cancel</button>
                            <button class="" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickUpdateBtn(activitiesObj)">Update</button>
                        </div> -->
                    </div>
                </div>
                
                <div v-if="selectedTabIndex == 1" class="pt-3 noScrollbar tabStructure1 overflow-y-auto">
                    <!-- {{ sortedDataActiveControls }} -->
                    <div class="tableCornerRadius">
                        <div class="table-container">
                            <table>
                                <thead>
                                    <th class="header cursor-pointer w-4" >
                                        <div class="flex items-center w-4 mx-2">
                                            <input type="checkbox" v-model="selectAllOptionActiveControls" class="checkbox cursor-pointer" :disabled="allControlsList.length == 0" @change="selectAllActiveControls($event)" />
                                        </div>
                                    </th>
                                    <th scope="col" v-for="column in activeControlHeaders" @click="sort(column)" :class="getHeaderProperties0('width',column)" :key="column" class="bg-white capitalize">
                                        <div class="flex items-center" :class="getHeaderProperties0('widthInner',column)">
                                            <div class="pl-5" v-if="column == 'controlPriority'">
                                                {{ 'Priority' }}
                                            </div>
                                            <div v-else>{{ getHeaderProperties0('name',column) }}</div>
                                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <tr v-if="showFilters">
                                        <td></td>
                                        
                                        <td v-for="(name, index) in activeControlHeaders" class="bg-white" :key="index">
                                            <select v-if="name == 'controlPriority'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbPriority" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                            <select v-else-if="name == 'controlFrequency'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in controlFrequencyArray" :key="pair" :value="pair.id">{{ pair.label }}</option>
                                            </select>

                                            <input v-else type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="allFilters[name]" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-if="sortedDataActiveControls.length == 0">
                                    <tr class="cursor-pointer rowHeight">
                                        <td class="relative h-10" :colspan="activeControlHeaders.length+1">
                                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="nonEmptyTable" v-else>
                                    <tr v-for="(dataObj,index) in sortedDataActiveControls" :key="index" class="cursor-pointer rowHeight">
                                        <td>
                                            <input class="checkbox mx-2" type="checkbox" v-model="checkedActiveControls" @change="changedActiveControlInput()" :value="dataObj._id" />
                                        </td>
                                        <td scope="col" v-for="column in activeControlHeaders" @click="showControlDetailsPopup(dataObj)" @mouseout="doFalse()" :key="column">
                                            
                                            <div v-if="column == 'controlFrequency'">
                                                <div>{{ getFrequencyValue( dataObj['controlFrequency'] ) }}</div>
                                            </div>

                                            <div v-else-if="column == 'controlPriority'">
                                                <div
                                                    class="flex items-center justify-center rounded h-5 w-24"
                                                    :class="colorLabelBg(dataObj[column])"
                                                >
                                                    <div :class="colorLabelText(dataObj[column])">
                                                        {{ getProirityLabel(dataObj[column]) }}
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div v-else>
                                                <div class="table-ellipsis">
                                                    <p class="fullText" @mousemove="toolTip(column, $event, index, dataObj, 'text')">
                                                        {{ dataObj[column] }}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="sortedDataActiveControls.length > 0">
                            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedDataActiveControls"></pagination>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>

                <div v-else-if="selectedTabIndex == 2" class="pt-3 noScrollbar tabStructure1 overflow-y-auto">
                    <div class="tableCornerRadius">
                        <div class="table-container">
                            <table>
                                <thead>
                                    <th class="header cursor-pointer w-4" >
                                        <div class="flex items-center w-4 mx-2">
                                            <input type="checkbox" v-model="selectAllOptionAllControls" class="checkbox cursor-pointer" :disabled="allControlsList.length == 0" @change="selectAllAllControls($event)" />
                                        </div>
                                    </th>
                                    <th scope="col" v-for="column in allControlHeaders" @click="sort(column)" :class="getHeaderProperties1('width',column)" :key="column" class="bg-white capitalize">
                                        <div class="flex items-center"  :class="getHeaderProperties1('widthInner',column)">
                                            <div class="pl-5" v-if="column == 'controlPriority'">
                                                {{ 'Priority' }}
                                            </div>
                                            <div v-else>{{ getHeaderProperties1('name',column) }}</div>
                                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <tr v-if="showFilters">
                                        <td></td>
                                        
                                        <td v-for="(name, index) in allControlHeaders" class="bg-white" :key="index">
                                            <select v-if="name == 'controlPriority'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbPriority" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                            <select v-else-if="name == 'controlFrequency'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in controlFrequencyArray" :key="pair" :value="pair.id">{{ pair.label }}</option>
                                            </select>

                                            <input v-else type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="allFilters[name]" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-if="sortedDataAllControls.length == 0">
                                    <tr class="cursor-pointer rowHeight">
                                        <td class="relative h-10" :colspan="allControlHeaders.length + 1">
                                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="nonEmptyTable" v-else>
                                    <tr v-for="(controlObject,index) in sortedDataAllControls" :key="index" class="cursor-pointer rowHeight">
                                        <td>
                                            <input class="checkbox mx-2" type="checkbox" v-model="checkedAllControls" @change="changedAllControlInput()" :value="controlObject._id" />
                                        </td>
                                        <td scope="col" v-for="column in allControlHeaders" @mouseout="doFalse()" :key="column">
                                            <div v-if="column == 'controlFrequency'">
                                                <div>{{ getFrequencyValue( controlObject[column] ) }}</div>
                                            </div>
                                            <div v-else-if="column == 'controlPriority'">
                                                <div
                                                    class="flex items-center justify-center rounded h-5 w-24"
                                                    :class="colorLabelBg(controlObject[column])"
                                                >
                                                    <div :class="colorLabelText(controlObject[column])">
                                                        {{ getProirityLabel(controlObject[column]) }}
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div v-else>
                                                <div class="table-ellipsis">
                                                    <p class="fullText" @mousemove="toolTip(column, $event, index, controlObject, 'text')">
                                                        {{ controlObject[column] }}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="sortedDataAllControls.length > 0">
                            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedDataAllControls"></pagination>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>
            <div v-if="selectedTabIndex == 0" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm"  style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5 mx-5">
                    <button class=" mr-1.5" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Cancel</button>
                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickUpdateBtn(activitiesObj)">Save</button>
                </div>
            </div>
            <div v-if="selectedTabIndex == 1" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm"  style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5 mx-5">
                    <button class="btnprimary mr-1.5" @click="cancelActiveControls()" :disabled="clickOnButton" :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'">Cancel</button>
                    <button class="btnprimary" @click="changeActiveControlsToActivity(10303)" :disabled="clickOnButton" :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'">Save</button>
                </div>
            </div>

            <div v-if="selectedTabIndex == 2" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm"  style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5 mx-5">
                    <button @click="onCancelAllControls()" :disabled="clickOnButton" :class="checkedAllControls.length == 0 ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary mr-1.5" >Cancel</button>
                    <button @click="mapTheSelectedControlsToActivity(10301)" :disabled="clickOnButton" :class="checkedAllControls.length == 0 ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
table {
  /* table-layout: fixed; */
  /* width: 100%; */
  background: #FFFFFF;
}
.w-40 {
    width: calc(100% - 170px);
}
.w-10 {
    width: calc(100% - 2.5rem);
}
.nonEmptyTable tr:last-child {
  border-bottom: 1px solid #e9e9e9;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.midHead {
    width: 160px !important;
}
.widthForSelect{
    width : 112px !important;
}
.widthofallcontrols{
    width : 10rem !important;
}
.widthofallcontrols1{
    width : 8rem !important;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.optionColors{
   color : #4D4D4D !important;
}
.borderRed {
    border: 1px solid red !important;
}
/* .tabStructure1 {
	height: calc(100vh - 360px);
} */
.noScrollbar::-webkit-scrollbar {
  display: none;
}

th {
  background-color: #FFFFFF;
  font-family: 'Open Sans' !important;
  cursor: default;
  display: table-cell;
  font-size: 14px !important;
  font-weight: 600;
  height: 60px;
  line-height: 19.5px;
  padding: 0px 10px ;
  /* border-bottom: 1px solid #e9e9e9 !important; */
  text-align: left;
  color: black;
 
}

td {
  font-size: 12px;
  font-weight: 400;
  height: 60px !important;
  line-height: 19.5px;
  padding: 0px 10px ;
  text-align: left;
  text-indent: 0px;
  margin: auto;
  box-sizing: border-box;
  font-family: "Open Sans" !important;
  color: black;
  border-bottom:0px;
}
.noScrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
</style>
<script lang="ts">
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import { emitter, toast } from '@/main';
import store from '@/store';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import breadCrumb from '@/components/breadCrumb.vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
const restrictCharacters = helpers.regex(/^.{0,300}$/);

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            selectedTabObj: { displayName: 'Process', name: 'Process', id: 0 },
            carouselHead: [
                { displayName: 'Activity Info', name: 'activatedControls', id: 0 },
                { displayName: 'Mandatory Controls', name: 'activatedControls', id: 1 },
                { displayName: 'All Controls', name: 'allControls', id: 2 },
            ],
            selectedTabIndex: null,
            showFilters: false,
            sideButton: false,
            clickOnButton: false,
           
            tenants: [],
            tenantsSelect: [],
            orgUsersList: [],
            usersList: [],
            optionsUserArray: [],
            activitiesObj: {
                name:'',
                departments:'',
                description:'',
                criticality:'',
            },
            allControlHeaders:['controlNumber','controlTitle','controlFamily','controlClass','controlFrequency','controlPriority'],
            tableProps1:{
                headerNames:[{key:'controlNumber',value:'ID'},{key:'controlTitle',value:'Title'},{key:'controlFamily',value:'Family'},{key:'controlClass',value:'Class'},{key:'controlFrequency',value:'Frequency'},{key:'controlPriority',value:'Priority'}],
                
                headerWidth:[{key:'controlNumber',value:'w-32'},{key:'controlTitle',value:'w-64'},{key:'controlFamily',value:'w-44'},{key:'controlClass',value:'w-48'},{key:'controlFrequency',value:'w-24'},{key:'controlPriority',value:'w-24'}],

                innerHeaderWidth:[{key:'controlNumber',value:'w-14'},{key:'controlTitle',value:'w-36'},{key:'controlFamily',value:'w-36'},{key:'controlClass',value:'w-36'},{key:'controlFrequency',value:'w-28'},{key:'controlPriority',value:'w-28'}],
            },

            activeControlHeaders:['controlNumber','controlTitle','controlFamily','controlClass','controlFrequency','controlPriority'],
            tableProps0:{
                headerNames:[{key:'controlNumber',value:'ID'},{key:'controlTitle',value:'Title'},{key:'controlFamily',value:'Family'},{key:'controlClass',value:'Class'},{key:'controlFrequency',value:'Frequency'},{key:'controlPriority',value:'Priority'}],
                
                headerWidth:[{key:'controlNumber',value:'w-32'},{key:'controlTitle',value:'w-64'},{key:'controlFamily',value:'w-44'},{key:'controlClass',value:'w-48'},{key:'controlFrequency',value:'w-24'},{key:'controlPriority',value:'w-24'}],

                innerHeaderWidth:[{key:'controlNumber',value:'w-14'},{key:'controlTitle',value:'w-36'},{key:'controlFamily',value:'w-36'},{key:'controlClass',value:'w-36'},{key:'controlFrequency',value:'w-28'},{key:'controlPriority',value:'w-28'}],
            },
            
           
            routesArray: [],

            activitiesData:[],
            allControlsList: [],
            allControlsListDisplay:[],
            allControlsListDummy:[],

            activeControlsList: [],
            activeControlsListDisplay:[],
            activeControlsListDummy:[],

            checkedAllControls: [],
            selectAllOptionAllControls: false,

            selectAllOptionActiveControls:false,
            checkedActiveControls:[],
            defaultCheckedActiveControls:[],

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            pageSize: 20,
            currentPage: 1,
            dynamicPagination: '',
            allFilters: {
                controlPriority:'',
                controlFrequency:''
            },

            controlTypeValues:[
                {
                    id:1,
                    value:'Mandatory'
                },
                {
                    id:2,
                    value:'Optional'
                },
                {
                    id:3,
                    value:'Custom'
                }
            ],

            controlFrequencyArray: [
				{ label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
			],

          
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens

            screenReduced : false,
            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,

            isSidebarToggle:true,

            customControlObject: {
                controlFrequency:"",
                controlNumber: "",
                controlPriority: "",
                controlTitle: "",
                controlClass: "",
                controlFamily: "",
                controlGuidance: "",
                controlAuditMethodology: "",
                controlAuditSuccessCriteria: "",
                controlMaintenanceTask: "",
                controlPolicies: "",
                controlType: "",
                controlOptionFrameWork: '',
                frameworkControls: '',
            },
            dummyCustomObj:{},
            customPopup:false,
            frameWorksList:[],
            frameJson:{},
            frameworkControls:[],
            filteredControlFamilyOptions:[],
            ControlFamilyArray:[],

            totalOrgControls:[],
            activitiesArray: [],
            teamsArray:[],
            teamsOptions:[],
            criticalityoptions: [
                {
                    id:1,
                    value:'Very Low'
                },
                {
                    id:2,
                    value:'Low'
                },
                {
                    id:3,
                    value:'Moderate'
                },
                {
                    id:4,
                    value:'High'
                },
                {
                    id:5,
                    value:'Very High'
                }
            ],
        };
    },
    components: {
        Multiselect,
        breadCrumb,
        toolTip,
        pagination
    },
    validations() {
        return {
            customControlObject:{
                controlNumber: { required },
                controlTitle: { required },
                controlFrequency: { required },
            },
            activitiesObj:{
                name:{ required,restrictCharacters },
                departments:{ required },
                description:{ required,restrictCharacters },
                criticality:{ required },
            }
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbPriority:'dbPriority',
        }),

        sortedDataAllControls: function (): any {
            return [...this.allControlsListDisplay]
                .filter((row: any) => {
                    for (const key in this.allFilters) {
                        const inputValue = this.allFilters[key];
                        if (inputValue !== '' && row[key]) {
                            if (row[key] == 'N/A') {
                                return false;
                            } 
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },

        sortedDataActiveControls: function (): any {
            return [...this.activeControlsListDisplay]
                .filter((row: any) => {
                    for (const key in this.allFilters) {
                        const inputValue = this.allFilters[key];
                        if (inputValue !== '' && row[key]) {
                            if (row[key] == 'N/A') {
                                return false;
                            } 
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },

        disableStatus: function (): any {
			let disableStatusCondition;

            if(this.customControlObject._id){
                if (JSON.stringify(this.customControlObject) == JSON.stringify(this.dummyCustomObj)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
            }else{
                let val: any = [];
                Object.values(this.customControlObject).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }

			return disableStatusCondition;
		},

        arraysAreEqual: function (): any {
            const sortedArray1 = [...this.checkedActiveControls].sort();
            const sortedArray2 = [...this.defaultCheckedActiveControls].sort();
            
            if (sortedArray1.length !== sortedArray2.length) {
                return false;
            }

            for (let i = 0; i < sortedArray1.length; i++) {
                if (sortedArray1[i] !== sortedArray2[i]) {
                return false;
                }
            }

            return true;
        }
    },
    methods: {
        getFrameworkControls(name:any){
            this.frameworkControls=[];
            let array=Object.values(this.frameJson)
            array.forEach((x:any)=>{
                x.forEach((y:any)=>{
                if(y.controlFrameWork == name){
                    this.frameworkControls.push(`${y.controlNumber}` + `--` + `${y.controlTitle }`);
                }
                })
            })
		},
        showControlDetailsPopup(data:any){
            this.customPopup = true;
            this.customControlObject = {...data};
            this.dummyCustomObj = {...data}
            this.getFrameworkControls(data.controlOptionFrameWork)
        },
        cancelActiveControls(){
            this.checkedActiveControls = [...this.defaultCheckedActiveControls];
            if(this.checkedActiveControls.length == this.activeControlsList.length && this.activeControlsList.length!=0){
                this.selectAllOptionActiveControls = true;
            }else{
                this.selectAllOptionActiveControls = false;
            }
        },
        saveActivityInfo(){
            console.log("fdgdfg")
        },
        cancelActivityInfo(){
            console.log("fdgdfg")
        },
        asyncFindControlFamily(query:any) {
            query = query.trim();
            if (query == '') {
                    this.filteredControlFamilyOptions = [];
            } else {
                this.filteredControlFamilyOptions = this.ControlFamilyArray.filter((item:any) => 
                    item?.toLowerCase().includes(query.toLowerCase())
                );
                let isNewOption = !this.ControlFamilyArray.some((item:any) => 
                    item?.toLowerCase() === query.toLowerCase()
                );
                if (isNewOption && query !== '') {
                    this.filteredControlFamilyOptions.push(query);
                }
            }
        },
        onCancelbtnCustom(prop:any){
            this.v$.$reset();
            
            if (prop === 'close') {
                this.customPopup = false;
                this.dummyCustomObj = {}
			}

            if(this.customControlObject._id){
                this.customControlObject = {...this.dummyCustomObj}
            }else{
                Object.keys(this.customControlObject).forEach((prop) => {
                Array.isArray(this.customControlObject[prop])
                    ? (this.customControlObject[prop] = [])
                    : (this.customControlObject[prop] = "");
                });
            }
            
        },
        onCancelAllControls(){
            this.checkedAllControls = [];
            this.selectAllOptionAllControls = false;
        },
        colorLabelText(value: any) {
            if (value == 1) {
                return ["text-lightgreen font-semibold"];
            } else if (value == 2) {
                return ["text-green font-semibold"];
            } else if (value == 3) {
                return ["text-yellow font-semibold"];
            } else if (value == 4) {
                return ["text-error font-semibold"];
            } else if (value == 5) {
                return ["text-darkred font-semibold"];
            }
        },
        colorLabelBg(value: any) {
            if (value == 1) {
                return ["bg-veryLowColor border-solid border border-lightgreen"];
            } else if (value == 2) {
                return ["bg-lowColor border-solid border border-green"];
            } else if (value == 3) {
                return ["bg-moderateColor border-solid border border-yellow"];
            } else if (value == 4) {
                return ["bg-highColor border-solid border border-error"];
            } else if (value == 5) {
                return ["bg-veryHighColor border-solid border border-darkred"];
            }
        },
        getProirityLabel(val:any){
            if (val === 'N/A') { 
                return 'N/A'; 
            } else {
                let a = this.dbPriority.find((obj: any) => obj.value == val);
                return (val && typeof val === 'number' && a != undefined) ? a.key : 'N/A';
            }
        },
        filtersOpen(){
             this.showFilters = !this.showFilters;
                if (this.showFilters == true) {
                    this.allControlsListDummy = this.allControlsListDisplay;
                } else {
                    this.allControlsListDisplay = this.allControlsListDummy;
                    for (const key in this.allFilters) {
                        this.allFilters[key] = '';
                    }
                }
        },
        getHeaderProperties1(prop:any,columnName:any){
            if(prop=='name'){
                let a = this.tableProps1.headerNames.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return columnName
                }

            }else if(prop=='width'){
                let a = this.tableProps1.headerWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }else if(prop=='widthInner'){
                let a = this.tableProps1.innerHeaderWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
        },

        getHeaderProperties0(prop:any,columnName:any){
            if(prop=='name'){
                let a = this.tableProps0.headerNames.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return columnName
                }

            }else if(prop=='width'){
                let a = this.tableProps0.headerWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }else if(prop=='widthInner'){
                let a = this.tableProps0.innerHeaderWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        getHeaderAlignment(column:any){
            // {key:'clientUniqueId',value:'CCID'},{key:'controlTitle',value:'Title'},{key:'createdAt',value:'Activated At'},{key:'controlFrequency',value:'Frequency'},{key:'classificationType',value:'Type'},{key:'controlPriority',value:'Priority'}
            if(column == 'clientUniqueId'){
                return 'w-24'
            } else if(column == 'controlTitle'){
                return 'w-24'
            } else if(column == 'createdAt'){
                return 'w-28'
            } else if(column == 'controlFrequency'){
                return 'w-28'
            } else if(column == 'controlPriority'){
                return 'w-28'
            } else{
                return ''
            }
            

        },
        paginationUpdations(currentPage: any, pageSize: any) {
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        selectAllAllControls(e: any) {
            this.checkedAllControls = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.allControlsList) {
                    this.checkedAllControls.push(obj._id);
                }
            } else {
                this.checkedAllControls = [];
            }
        },
        selectAllActiveControls(e:any){
            this.checkedActiveControls = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.activeControlsList) {
                    this.checkedActiveControls.push(obj._id);
                }
            } else {
                this.checkedActiveControls = [];
            }
        },
        changedAllControlInput(){
            if(this.checkedAllControls.length == this.allControlsList.length){
                this.selectAllOptionAllControls = true;
            }else{
                 this.selectAllOptionAllControls = false;
            }
        },
        changedActiveControlInput(){
            if(this.checkedActiveControls.length == this.activeControlsList.length && this.activeControlsList.length!=0){
                this.selectAllOptionActiveControls = true;
            }else{
                 this.selectAllOptionActiveControls = false;
            }
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.toolTipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            // switch (status) {
            //     case 'text':
            this.handleTextTooltip(e);
            //         break;
            // }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.toolTipText
                }

                emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        async toggleTabs(tabId: any) {
            this.dataLoading = true
            if (tabId.id == 1) {
                this.currentPage = 1
                this.filtersOpen();
                emitter.emit('visible', false);
                this.showFilters = false;
                await this.getActiveControlsForActivity();
            } else if (tabId.id == 2) {
                this.currentPage = 1
                this.filtersOpen();
                emitter.emit('visible', false);
                this.showFilters = false;
                await this.getAllControls();
            }

            this.selectedTabIndex = tabId.id;
            sessionStorage.setItem('selectedTab', tabId.id);
            this.selectedTabObj = tabId;
            console.log("jhygfd",)
            
            this.dataLoading = false
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.usersList = res.data;
                this.orgUsersList = res.data;
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        getControlTypeValue(a:any){
            let val = this.controlTypeValues.find((item:any)=>{ return item.id == a });
            if(val != undefined){
                return val.value
            }else{
                return 'N/A'
            }
        },

        getFrequencyValue(a:any){
            let val = this.controlFrequencyArray.find((item:any)=>{ return item.id == a });
            if(val != undefined){
                return val.label
            }else{
                return 'N/A'
            }
        },

        async getAllControls(){
        
            this.allControlsListDisplay = [];
            this.allControlsListDummy = [];
            this.ControlFamilyArray = []
            // route: /root/activity/:activityId/allcontrols/get
            let activityid:any = this.$route.params.activityId
            try{
                await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/activity/${activityid}/allcontrols/get`).then((result: { data: any }) => {
                    this.allControlsList = [...result.data];

                    result.data.map((obj:any)=>{
                        let a = {
                            "_id":obj._id,
                            "controlNumber": obj.controlNumber == '' ? 'N/A': obj.controlNumber,
                            'controlTitle': obj.controlTitle == '' ? 'N/A': obj.controlTitle,
                            'controlFamily': obj.controlFamily == '' ? 'N/A': obj.controlFamily,
                            'controlClass': obj.controlClass == '' ? 'N/A': obj.controlClass,
                            'controlFrequency': obj.controlFrequency == '' || obj.controlFrequency == undefined ? 'N/A': obj.controlFrequency,
                            'controlPriority': (isNaN(obj.controlPriority) || obj.controlPriority === '') ? 'N/A' : obj.controlPriority
                        }

                        if (Object.keys(obj).includes('controlFamily') && (obj.controlFamily != "" || obj.controlFamily != null || obj.controlFamily != undefined) && !this.ControlFamilyArray.includes(obj.controlFamily)) {
                            this.ControlFamilyArray.push(obj.controlFamily);
                        }

                        this.allControlsListDisplay.push({...a});
                        this.allControlsListDummy.push({...a})
                    })


                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }

        },

        async getActiveControlsForActivity(){
            this.activeControlsList = []
            this.activeControlsListDisplay = []
            this.activeControlsListDummy = [];
            this.defaultCheckedActiveControls = [];
            this.checkedActiveControls = []
//             getall activated controls
 
//  route:/root/activity/:activityId/mappedcontrol/get
            let activityid:any = this.$route.params.activityId
            console.log("dfgdfgdfg",activityid)
            try{
                await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/activity/${activityid}/mappedcontrol/get`).then((result: { data: any }) => {
                    console.log("dfgdfgdfg",result.data)
                    this.allControlsList = [...result.data];
                    result.data.map((obj:any)=>{
                        let a = {
                            "_id":obj._id,
                            "controlNumber": obj.controlNumber == '' || obj.controlNumber == undefined? 'N/A': obj.controlNumber,
                            'controlTitle': obj.controlTitle == '' || obj.controlTitle == undefined? 'N/A': obj.controlTitle,
                            'controlFamily': obj.controlFamily == '' || obj.controlFamily== undefined? 'N/A': obj.controlFamily,
                            'controlClass': obj.controlClass == '' || obj.controlClass == undefined? 'N/A': obj.controlClass,
                            'controlFrequency': obj.controlFrequency == ''|| obj.controlFrequency == undefined? 'N/A': obj.controlFrequency,
                            'controlPriority': (isNaN(obj.controlPriority) || obj.controlPriority === '') ? 'N/A' : obj.controlPriority
                        }

                        if (Object.keys(obj).includes('controlFamily') && (obj.controlFamily != "" || obj.controlFamily != null || obj.controlFamily != undefined) && !this.ControlFamilyArray.includes(obj.controlFamily)) {
                            this.ControlFamilyArray.push(obj.controlFamily);
                        }

                        this.activeControlsListDisplay.push({...a});
                        this.activeControlsListDummy.push({...a})
                        // this.activeControlsList.push({...a})
                        // this.checkedActiveControls.push({...a})
                        console.log("this.jlkfdg",this.activeControlsListDisplay)
                    })


                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            // let dummy:any = [];

            this.activeControlsListDisplay.map((obj:any,index:any)=>{
            //     obj.classificationType = 1;
            //     obj.createdAt = this.getDateFormat(new Date());
                // if(index == 0){
                    this.checkedActiveControls.push(obj._id)
                    this.defaultCheckedActiveControls.push(obj._id)
                // }
            })
           

            this.activeControlsList = [...this.activeControlsListDisplay]
            this.activeControlsListDisplay = [...this.activeControlsListDisplay];
            this.activeControlsListDummy = [...this.activeControlsListDisplay];

            if(this.checkedActiveControls.length == this.activeControlsList.length && this.activeControlsList.length!=0){
                this.selectAllOptionActiveControls = true;
            }else{
                this.selectAllOptionActiveControls = false;
            }
            
        },
        getDateFormat(date: any) {
            if (date == "") {
                return "None";
            } else {
                let now = moment(date);
                return now.format("DD-MM-YYYY");
            }
        },

        // async getAllControls(frameName: any) {
        //     this.totalOrgControls = [];

		// 	try {
		// 		const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`);
				
		// 		this.totalOrgControls = result.data;

		// 		let arr: any = [];

        //         if((this.totalOrgControls != null || this.totalOrgControls != undefined) && this.totalOrgControls.length != 0){
        //             this.totalOrgControls.forEach((item: any) => {
        //                 if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !Object.keys(item).includes('isInternalControl')) {
        //                     arr.push(item);
        //                     this.frameJson = this.groupByFrameworks(arr);
        //                 }
        //             });
                    
        //             let displayFrameName = '';	
        //             if(this.frameJson != undefined){
        //                 Object.keys(this.frameJson).forEach((key, index) => {
        //                     if (frameName === index) {
        //                         displayFrameName = key;
        //                     }
        //                     this.frameWorksList.push(key);
        //                 });
        //             }
        //         }
		// 	} catch (e) {
		// 		toast.error(`Something went wrong`, {
		// 			timeout: 1000,
		// 			closeOnClick: true,
		// 			closeButton: 'button',
		// 			icon: true
		// 		});
		// 	}
		// },
        groupByFrameworks(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
        
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
       
        scrollTabs(direction:any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs('tab');
        },
 
        handleResize() {
            const width = window.innerWidth;
            if (width <= 427) {
                this.tabsPerScreen = 1;
            } else if (width <= 569) {  
                this.tabsPerScreen = 3;
            } else if (width <= 700) {
                this.tabsPerScreen = 5;
            } else {
                this.tabsPerScreen = this.carouselHead.length; // Show all tabs for larger screens
            }

            this.isSmallScreen = (width <= 500); // For logic buttons ref

            // Calculate total groups
            this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerScreen);

            // Determine the group containing the selected tab
            this.updateCurrentGroupIndex();
            this.updateVisibleTabs('normal');
            this.updateButtonStates();
        },

        updateCurrentGroupIndex() {
            const selectedTabIndex = this.carouselHead.findIndex((tab:any) => tab.id === this.selectedTabIndex);
            if (selectedTabIndex >= 0) {
                this.currentGroupIndex = Math.floor(selectedTabIndex / this.tabsPerScreen);
            } else {
                this.currentGroupIndex = 0; // Reset to first group if selected tab not found
            }
        },

        updateVisibleTabs(from:any) {
            if (this.tabsPerScreen === this.carouselHead.length) {
                this.visibleTabs = [...this.carouselHead]; // Show all tabs
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerScreen;
                const endIdx = startIdx + this.tabsPerScreen;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
            }

            if(from=='tab'){
                this.toggleTabs(this.visibleTabs[0])
            }
        },


        async mapTheSelectedControlsToActivity(type:any){
             this.clickOnButton = true 
            //  add control to activity(add or delete control)
 
            // route:/root/activity/:activityId/:type/contolsmapping
            
            // payload:
            // {
            //     "mappedControls":["6733201ae4f8dc9530c0732a","6733201ae4f8dc9530c0732b"]
            // }
            let activityid:any = this.$route.params.activityId
            let payload:any = {
                mappedControls :  this.checkedAllControls
            }

            try{
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/activity/${activityid}/${type}/contolsmapping`,payload).then((res: any) => {
                    toast.info('Updated Sucessfully', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                })
            }catch(e:any){
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    })
            }finally{
                this.clickOnButton = false 
            }
            await this.getAllControls();
            this.checkedAllControls = []
            this.selectAllOptionAllControls = false;
        },

        async changeActiveControlsToActivity(type:any){
            this.clickOnButton = true 
            let obj:any = []
            this.defaultCheckedActiveControls.forEach((id:any) => {
                if (!this.checkedActiveControls.includes(id)) {
                obj.push(id);
                }
            });
            console.log('CHANGEEEEE',obj)
            
            let activityid:any = this.$route.params.activityId
            let payload:any = {
                mappedControls :  obj
            }
            
            try{
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/activity/${activityid}/${type}/contolsmapping`,payload).then((res: any) => {
                    toast.info('Updated Sucessfully', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                })
            }catch(e:any){
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    })
            }finally{
                this.clickOnButton = false 
            }

            await this.getActiveControlsForActivity();
        },
        
        async getAllActivities(){
            this.activitiesArray=[]
            this.activitiesData=[]
            this.dataLoading = true
            await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/activities/getall`).then((res: any) => {
				this.activitiesData = res.data
			})
            let id:any = this.$route.params.activityId
            let obj:any = this.activitiesData.find((data:any)=> {return data._id == id})
            let activityDept:any
            obj.departments.forEach((deptId: any) => {
                activityDept = this.teamsArray.find((team: any) => team.value === deptId);
                
            });

            // data.departments = activityDept
            let obj1:any={
                _id:obj._id,
                name:obj.name,
                description:obj.description,
                criticality:obj.criticality,
                departments:activityDept
            }
            // console.log("hjgkhgjk",obj)
            // this.activitiesObj = {...obj };
            console.log("hfsdghgfdh",obj)
            this.activitiesObj = {...obj1}
                // this.activitiesData.forEach((activity:any)=>{
                // let activitydept:any
                // let activitycriticality:any
                //     activity.departments.map((dept:any)=>{
                //         const department = this.teamsArray.find((team: any) => team.value === dept);

                //         if (department) {
                //             activitydept = department; 
                //         }
                //     })
                //     activitycriticality = this.criticalityoptions.find((obj:any)=>{return obj.id == activity.criticality})
                    
                //     let obj:any = {
                //         _id:activity._id,
                //         name:activity.name,
                //         description:activity.description,
                //         departments:activitydept!=null||activitydept!=undefined?activitydept.label:'N/A',
                //         criticality:activitycriticality.id
                //     }
                //     this.activitiesArray.push(obj)
                // })
                this.dataLoading = false
                console.log('cameobject111',this.activitiesData)
        },
        async getAllTeams(){
            this.teamsArray=[]
            await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/teams/getall`).then((res: any) => {
				let teamData:any = res.data
                console.log("lkjhfgdlk2",teamData)
                let obj:any =[]
                teamData.forEach((team:any)=>{
                    obj.push({label: team.displayName, value :team._id})
                    this.teamsArray=obj
                })
                console.log("lkjhfgdlk22",obj)
                console.log("lkjhfgdlk1",this.teamsArray)

			})
        },
        
		asyncdepartmentDisplayFind(query:any){
			if(query == ''){
				console.log("intooo empty",query)
				this.teamsOptions = []
			}else if(query.length >= 1){
				this.teamsOptions = this.teamsArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        onCancelbtn(){
            this.activitiesObj = {...this.dummyactivitiesObj}
        },
        async onClickUpdateBtn(obj:any){
            this.v$.$touch();
            console.log("kjdfgh",this.v$.activitiesObj.$invalid)
                console.log("kjdfgh",obj)
                if(!this.v$.activitiesObj.$invalid){
                
                this.clickOnButton = true
                    //     /root/teams/activities/mapping/:id

                    //     // payload:
                    //     //     {
            //     "name":"activity-04",
            //     "departments":["673ecb9d514c39a4d309a55f"],
            //     "criticality":5
            // }
            const departmentValues = obj.departments.value;

            // Construct the payload
            let id = obj._id
            const payload = {
                name: obj.name,  // Only send the 'value' of the department
                criticality: obj.criticality,
                description: obj.description,
                departments: [departmentValues],
            };
            
            this.dataLoading = true
            console.log("fsdhjg",payload)
            try {
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/teams/activities/mapping/${id}`,payload).then((res: any) => {
                    console.log("fsdhjg",payload)
                    toast.info('Updated Sucessfully', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
                await this.getAllActivities();
                this.v$.$reset();
                this.dummyactivitiesObj = {...this.activitiesObj}
                
            this.dataLoading = false
        }
        },
    },
    async mounted() {
        this.visibleTabs = [...this.carouselHead];
        
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.updateButtonStates(); 

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});
    },
    async created() {
        this.routesArray = [
            { name: "Activities", routeName: "activities" },
            { name: 'Control Mapping', routeName: "" },
        ];

        this.dataLoading = true;

        await this.getallUsers();
        await this.getAllTeams();
        await this.getAllTenants();
        await this.getAllActivities();
        this.dummyactivitiesObj = {...this.activitiesObj}
        
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        
        savedTabKey = parseInt(savedTabKey)
		if (savedTabKey) {
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Activity info', name: 'Process', id: 0 },
                this.selectedIndex = 0;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Activity info', name: 'Process', id: 0 },
			this.selectedIndex = 0;
            await this.toggleTabs(this.selectedTabObj);
		}

        this.dataLoading = false;
        
        const windowWidth = window.innerWidth
        if(windowWidth <= 1150){
            this.screenReduced = true
        } else {
            this.screenReduced = false
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab');
    },
});
</script>
