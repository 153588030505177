<template>
    <div class="flex flex-col items-center w-full h-screen paddingContainer overflow-y-auto">
        <!-- <Transition name="dynematrix-text-process"> -->
            <div class="w-auto mt-3">
                <h1 class="text-primary text-md text-center">Welcome to DYNEMATRIX !</h1>
                <p class="text-primary text-xs text-center mt-1">To get the best experience, we recommend you to complete these onboarding steps</p>
            </div>
        <!-- </Transition> -->
        <div class="bg-white flex justify-between gap-5 border mt-3 border-lightgrey rounded infoContainer w-full p-5">
            
            <!-- <Transition name="onBoardBoxes"> -->
                <div class="w-1/5 relative h-full flex flex-col justify-between pr-1.5 overflow-y-auto scrollBarColour">
                    <div v-for="presentTab,myIndex in onBoardTabs" :key="myIndex" class="flex flex-col items-center">
                        <div
                            @click="goToTabSelected(presentTab.id)"
                            :class="{
                                'border border-greenBorder z-50 bg-lightgrey cursor-pointer':completedTabs.includes(presentTab),
                                'border border-onBoardBlue z-50 bg-onBoardBlueBg cursor-pointer':selectedTabIndex == presentTab.id,
                                'bg-white z-50 border border-lightgrey': presentTab.id > selectedTabIndex
                            }"
                            class="flex w-full justify-center items-center rounded presentTabBoxHeight"
                        >
    
                            <div v-if="completedTabs.includes(presentTab)" class="w-2/6 z-50 flex justify-center">
                                <div class="h-5 w-5 rounded-full bg-tickGreen flex justify-center items-center">
                                    <img src="../assets/Check.svg" class="h-3.5 icon" />
                                </div>  
                            </div>
                            <div v-else class="w-2/6 z-50 flex justify-center">
                                <div class="h-6 w-6 rounded-full circleBackground flex justify-center items-center">
                                    <img :src="presentTab.imageSrc" class="h-4 icon" />
                                </div>
                            </div>
                            <div class="w-4/6 text-sm font-bold cusror-pointer" :class="presentTab.displayName.length > 5? 'table-ellipsis': 'table-ellipsis'"  @mousemove="toolTip( $event, presentTab.displayName, 'text')"  @mouseout="doFalse()">{{presentTab.displayName}}</div>
                            <div v-if="toolTipActive">
                                <tool-tip :tipText="toolTipText"></tool-tip>
                            </div>
                    
                        </div>
                    </div>

                    <div class="barLine absolute">
                        <div 
                            v-for="num in progressNumbers" :key="num" 
                            class="border border-lightgrey border-dashed h-1/5 mt-0.5" 
                            :class="{'border-onBoardBlue': completedTabs.includes(onBoardTabs[num])}"
                        >
                        </div>
                    </div>
                
                </div>
            <!-- </Transition> -->
           
            <div class="border border-l-0 border-lightgrey"></div>
            
            <div class="w-4/5 h-full overflow-y-auto">
                <div class="w-full dataContainer" :class="selectedTabIndex == 2 ? '': 'border border-lightgrey rounded'" >
                    <div v-if="selectedTabIndex == 0" class="h-full w-full px-2 pb-2 pt-3">
                        <!-- <Transition name="orgForm"> -->
                            <div ref="scrollContainerOrg" @click="handleOrgFormScroll" class="h-full w-full overflow-y-auto px-2 scrollBarColour">
                                <div class="row flex flex-row w-full">
                                    <div class="w-1/2">
                                        <label class="controllabel">Organisation Legal Name</label>
                                        <input
                                            v-model.trim="onBoardObject.organizationName"
                                            placeholder="Organisation Legal Name"
                                            class="inputboxstyling mt-2"
                                            @click="handleOrgFormScroll"
                                            @input="v$.onBoardObject.organizationName.$touch()"
                                            :class="{
                                                'is-invalid': (v$.onBoardObject.organizationName.$error || isDuplicateData.organizationName)
                                            }"
                                        />
                                        <div v-if="v$.onBoardObject.organizationName.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.onBoardObject.organizationName.required.$invalid">Required</div>
                                            <div v-else-if="v$.onBoardObject.organizationName.required && v$.onBoardObject.organizationName.restrictCharacters60.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                        </div>
                                        <div v-if="isDuplicateData.organizationName" class="text-red-500">
                                            <div class="error-text text-xs">The Organization Name already exists.</div>
                                        </div>
                                    </div>
                                    <div class="w-1/2 leftspacing">
                                        <label class="controllabel">Display Name</label>
                                        <input
                                            v-model.trim="onBoardObject.displayName"
                                            placeholder="Display Name"
                                            class="inputboxstyling mt-2"
                                            @click="handleOrgFormScroll"
                                            @input="v$.onBoardObject.displayName.$touch()"
                                            :class="{
                                                'is-invalid': (v$.onBoardObject.displayName.$error || isDuplicateData.displayName)
                                            }"
                                        />
                                        <div v-if="v$.onBoardObject.displayName.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.onBoardObject.displayName.required.$invalid">Required</div>
                                            <div v-else-if="v$.onBoardObject.displayName.required && v$.onBoardObject.displayName.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                        </div>
                                        <div v-if="isDuplicateData.displayName" class="text-red-500">
                                            <div class="error-text text-xs">Display Name already exists.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Website</label>
                                            <input
                                                v-model.trim="onBoardObject.websiteUrl"
                                                placeholder="Website"
                                                class="inputboxstyling mt-2"
                                                @click="handleOrgFormScroll"
                                                @input="v$.onBoardObject.websiteUrl.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.websiteUrl.$error || isDuplicateData.websiteUrl)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.websiteUrl.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.websiteUrl.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.websiteUrl.required && v$.onBoardObject.websiteUrl.restrictCharacters100.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                                <div v-else-if="v$.onBoardObject.websiteUrl.required &&  v$.onBoardObject.websiteUrl.websiteValidate.$invalid" class="error-text text-xs">Invalid Website</div>
                                            </div>
                                            <div v-if="isDuplicateData.websiteUrl" class="text-red-500">
                                                <div class="error-text text-xs">websiteUrl already exists.</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Organisation Key</label>
                                            <input
                                                v-model.trim="onBoardObject.organizationKey"
                                                placeholder="Organisation Key"
                                                class="inputboxstyling mt-2"
                                                @click="handleOrgFormScroll"
                                                @input="v$.onBoardObject.organizationKey.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.organizationKey.$error || isDuplicateData.organizationKey)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.organizationKey.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.organizationKey.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.organizationKey.required && v$.onBoardObject.organizationKey.restrictCharacters5.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                                <div v-else-if="v$.onBoardObject.organizationKey.required && v$.onBoardObject.organizationKey.textwithNumsOnly.$invalid" class="error-text text-xs">Invalid Key</div>
                                            </div>
                                            <div v-if="isDuplicateData.organizationKey" class="text-red-500">
                                                <div class="error-text text-xs">Organization Key already exists.</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Region</label>
                                            <input
                                                v-model.trim="onBoardObject.region"
                                                placeholder="Region"
                                                class="inputboxstyling mt-2"
                                                @click="handleOrgFormScroll"
                                                @input="v$.onBoardObject.region.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.region.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.region.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.region.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.region.required && v$.onBoardObject.region.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Industry Sector</label>
                                            <input
                                                v-model.trim="onBoardObject.industrySector"
                                                placeholder="Industry Sector"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.industrySector.$touch()"
                                                @click="handleOrgFormScroll"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.industrySector.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.industrySector.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.industrySector.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.industrySector.required && v$.onBoardObject.industrySector.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <div class="upperspacing">
                                        <label class="controllabel">Logo</label>
                                        <div class="w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2" :class="{'is-invalid': (v$.onBoardObject.logoName.$error)}">
                                            <input type="file" accept=".jpg, .jpeg, .png, .svg" ref="fileInput" class="hidden" id="upload" @change.stop="updateFileName($event)" :disabled="onBoardObject._id" />
                                            <label for="upload" class="float-left cursor-pointer flex text-black text-xs bg-lightgrey pl-2 pr-4 py-2"><img src="../assets/attachIcon.svg" class="px-2">Attach</label>
                                            <span @click="handleOrgFormScroll" id="fileNameSpan" class=" items-center justify-between flex" :class="(onBoardObject.logoName)?'uploadedFileNameStyles':'placeholder-styles'" v-if="!onBoardObject._id && !onBoardObject.logoName">{{ onBoardObject.logoName ? onBoardObject.logoName.name : placeholder }}</span>
                                            <span @click="handleOrgFormScroll" id="fileNameSpan" class=" items-center justify-between flex" :class="(onBoardObject.logoName)?'uploadedFileNameStyles':'placeholder-styles'" v-else-if="!onBoardObject.logoName || !onBoardObject.logoName.name">{{ onBoardObject.logoName ? onBoardObject.logoName : placeholder }}</span>
                                            <span @click="handleOrgFormScroll" id="fileNameSpan" class=" items-center justify-between flex" :class="(onBoardObject.logoName)?'uploadedFileNameStyles':'placeholder-styles'" v-else>{{ onBoardObject.logoName ? onBoardObject.logoName.name : placeholder }}</span>
                                        </div>
                                        <div v-if="v$.onBoardObject.logoName.$error" class="text-red-500">
                                            <div class="error-text text-xs mx-2" v-if="v$.onBoardObject.logoName.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upperspacing">
                                    <div>
                                        <label class="controllabel">Address line - 1</label>
                                        <input
                                            v-model.trim="onBoardObject.addressLineOne"
                                            placeholder="Address line - 1"
                                            class="inputboxstyling mt-2"
                                            @click="handleOrgFormScroll"
                                            @input="v$.onBoardObject.addressLineOne.$touch()"
                                            :class="{
                                                'is-invalid': (v$.onBoardObject.addressLineOne.$error)
                                            }"
                                        />
                                        <div v-if="v$.onBoardObject.addressLineOne.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.onBoardObject.addressLineOne.required.$invalid">Required</div>
                                            <div v-else-if="v$.onBoardObject.addressLineOne.required && v$.onBoardObject.addressLineOne.restrictCharacters300.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upperspacing">
                                    <div>
                                        <label class="controllabel">Address line - 2</label>
                                        <input
                                            v-model.trim="onBoardObject.addressLineTwo"
                                            placeholder="Address line - 2"
                                            class="inputboxstyling mt-2"
                                            @click="handleOrgFormScroll"
                                            @input="v$.onBoardObject.addressLineTwo.$touch()"
                                            :class="{
                                                'is-invalid': (v$.onBoardObject.addressLineTwo.$error)
                                            }"
                                        />
                                        <div v-if="v$.onBoardObject.addressLineTwo.$error" class="text-red-500">
                                            <div v-if="v$.onBoardObject.addressLineTwo.restrictCharacters300.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">City</label>
                                            <input
                                                v-model.trim="onBoardObject.city"
                                                placeholder="City"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.city.$touch()"
                                                @click="handleOrgFormScroll"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.city.$error)
                                                }"
                                            />
                                            <!-- <multiselect 
                                                v-model="onBoardObject.city" 
                                                :options="citiesList"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                :hide-selected="true"
                                                :disabled="onBoardObject.country == ''"   
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="City"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.onBoardObject.city.$error,
                                                    'has-options': citiesList && citiesList.length > 0,
                                                    'no-options' :  !citiesList.length && onBoardObject.country
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No City Found</span>
                                            </template>
                                            </multiselect> -->
                                            <div v-if="v$.onBoardObject.city.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.city.required.$invalid">Required</div>
                                                <div v-if="v$.onBoardObject.city.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                                <div v-else-if="v$.onBoardObject.city.required && v$.onBoardObject.city.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">State</label>
                                            <input
                                                v-model.trim="onBoardObject.state"
                                                @input="v$.onBoardObject.state.$touch()"
                                                @click="handleOrgFormScroll"
                                                placeholder="State"
                                                class="inputboxstyling mt-2"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.state.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.state.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.state.required.$invalid">Required</div>
                                                <div v-if="v$.onBoardObject.state.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                                <div v-else-if="v$.onBoardObject.state.required && v$.onBoardObject.state.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Country</label>
                                            <input
                                                v-model.trim="onBoardObject.country"
                                                @input="v$.onBoardObject.country.$touch()"
                                                @click="handleOrgFormScroll"
                                                placeholder="Country"
                                                class="inputboxstyling mt-2"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.country.$error)
                                                }"
                                            />
                                            <!-- <multiselect 
                                                v-model="onBoardObject.country" 
                                                :options="countriesList"
                                                :multiple="false"
                                                @select="changeCountry(onBoardObject.country)"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                :hide-selected="true"
                                                :disabled="onBoardObject.country == ''"   
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="City"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.onBoardObject.city.$error,
                                                    'has-options': citiesList && citiesList.length > 0,
                                                    'no-options' :  !citiesList.length && onBoardObject.country
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No City Found</span>
                                            </template>
                                            </multiselect> -->
                                            <div v-if="v$.onBoardObject.country.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.country.required.$invalid">Required</div>
                                                <div v-if="v$.onBoardObject.country.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                                <div v-else-if="v$.onBoardObject.country.required && v$.onBoardObject.country.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Pincode</label>
                                            <input
                                                v-model.trim="onBoardObject.pinCode"
                                                type="number"
                                                placeholder="Pincode"
                                                class="inputboxstyling mt-2"
                                                @click="handleOrgFormScroll"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.pinCode.$error)
                                                }"
                                                @input="v$.onBoardObject.pinCode.$touch()" 
                                            />
                                            <div v-if="v$.onBoardObject.pinCode.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.pinCode.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.pinCode.required && v$.onBoardObject.pinCode.pin.$invalid" class="error-text text-xs">Pin Code is Invalid</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Primary Contact Name</label>
                                            <input
                                                v-model.trim="onBoardObject.primaryContactName"
                                                placeholder="Primary Contact Name"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.primaryContactName.$touch()"
                                                @click="handleOrgFormScroll"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.primaryContactName.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.primaryContactName.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.primaryContactName.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactName.required && v$.onBoardObject.primaryContactName.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactName.required && v$.onBoardObject.primaryContactName.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Primary Contact Mail id</label>
                                            <input
                                                v-model.trim="onBoardObject.primaryContactEmail"
                                                placeholder="Primary Contact Mail id"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.primaryContactEmail.$touch()"
                                                @click="handleOrgFormScroll"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.primaryContactEmail.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.primaryContactEmail.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.primaryContactEmail.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactEmail.required && v$.onBoardObject.primaryContactEmail.email.$invalid" class="error-text text-xs">Email is Invalid</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactEmail.required && v$.onBoardObject.primaryContactEmail.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Job Title</label>
                                            <input
                                                v-model.trim="onBoardObject.jobTitle"
                                                placeholder="Job Title"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.jobTitle.$touch()"
                                                @click="handleOrgFormScroll"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.jobTitle.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.jobTitle.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.jobTitle.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.jobTitle.required && v$.onBoardObject.jobTitle.restrictCharacters30.$invalid" class="error-text text-xs">Character Limit exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </Transition> -->
                    </div>
                    <div v-else-if="selectedTabIndex == 1" class="p-2 h-full w-full">
                       <div class="overflow-y-scroll h-full w-full scrollBarColour">
                            <div class="flex flex-wrap gap-2 p-2">
                                <label v-for="team in teamsList" :key= team.id class="flex items-center overflow-hidden teamWidth h-10 border border-lightgrey rounded cursor-pointer">
                                    <div class="flex justify-between items-center">
                                        <div class="flex justify-center p-2">
                                            <div class="flex justify-center items-center">
                                                <input v-model="selectedTeamList" :value="team" type="checkbox" class="checkBoxStyles">
                                            </div>
                                        </div>
                                        <div class="text-xs font-bold">{{team.displayName}}</div>
                                    </div>
                                </label>
                        </div>
                       </div>
                    </div>
                    <div v-else-if="selectedTabIndex == 2" class="h-full w-full">
                        <div class="h-full w-full overflow-y-scroll scrollBarColour">
                            <div class="h-full w-full pr-2">
                                <newTable :tableRowData="activitiesList" :headersData="headers" :openPopup="false" :navigate="false" :buttonType="buttonType" :dotsLoading = spinnerLoading></newTable>
                                <div v-if="toolTipActive">
                                    <tool-tip :tipText="toolTipText"></tool-tip>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-else-if="selectedTabIndex == 3" class="h-full w-full p-2">                   
                        <div class="h-full w-full">
                            <div class="flex flex-row gap-2 justify-between w-full h-full">
                                <div class="w-1/2 flex flex-col gap-1">
                                    <h1 class="text-primary text-md">All Systems</h1>
                                    <input class="inputboxstyling placeholderImg" type="text" placeholder="Search Option for All Systems" v-model="searchTerm"/>
                                    <div class="overflow-y-scroll h-full w-full mt-1 scrollBarColour">
                                        <div v-for="(systems, category) in groupedSystemsCollectionArray" :key="category" class="mt-1">
                                            <div class="flex flex-row w-full h-full justify-center items-center">
                                                <div class="flex justify-center items-center w-full h-6 border categoryBackground border-greenBorder rounded-full">
                                                    <h1 class="text-xs font-bold">{{ category }}</h1>
                                                </div>
                                                <div class="border border-b-0 border-lightgrey w-full mr-2.5"></div>
                                            </div>
                                            <div class="flex flex-wrap gap-2 py-2 pr-2">
                                                <div v-for="system in systems" :key="system._id" class="flex justify-center items-center p-1 systemWidth h-8 border border-lightgrey rounded cursor-pointer" @click="groupedSelectedSystems(system)">
                                                    <div class="w-1/6 flex justify-center">
                                                        <div class="flex justify-center items-center pr-1">
                                                            <img :src="system.systemSrc" alt="System Image" class="w-5 h-5" />
                                                        </div>
                                                    </div>
                                                    <div class="w-5/6 text-xs">{{system.systemName}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="border border-l-0 border-lightgrey h-full"></div>
                                <div class="w-1/2 flex flex-col gap-1">
                                    <h1 class="text-primary text-md">Selected Systems</h1>
                                    <input class="inputboxstyling placeholderImg" type="text" placeholder="Search Option for Selected Systems" v-model="searchSelectedSystem"/>
                                    <div class="overflow-y-scroll h-full w-full scrollBarColour">
                                        <div class="flex flex-wrap gap-2 py-2 pr-2">
                                            <div v-for="system in selectedSystemsCollectionArray" :key="system._id" class="flex justify-center items-center p-1 systemWidth h-8 border border-lightgrey rounded cursor-pointer" @click="groupedDeselectedSystems(system)">
                                                <div class="w-1/6 flex justify-center">
                                                    <div class="flex justify-center items-center pr-1">
                                                        <img :src="system.systemSrc" alt="System Image" class="w-5 h-5" />
                                                    </div>
                                                </div>
                                                <div class="w-5/6 text-xs">{{system.systemName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="selectedTabIndex == 4" class="h-full w-full p-2">
                        <div class="h-full w-full">
                            <div class="flex flex-row gap-2 justify-between w-full h-full">
                                <div class="w-1/2 flex flex-col gap-1">
                                    <h1 class="text-primary text-md">All Vendors</h1>
                                    <input class="inputboxstyling placeholderImg" type="text" placeholder="Search Option for All Vendors" v-model="searchVendor"/>
                                    <div class="overflow-y-scroll h-full w-full mt-1 scrollBarColour">
                                        <div v-for="(vendors, category) in groupedVendorsCollectionArray" :key="category" class="mt-1">
                                            <div class="flex flex-row w-full h-full justify-center items-center">
                                                <div class="flex justify-center items-center w-full h-6 border categoryBackground border-greenBorder rounded-full">
                                                    <h1 class="text-xs font-bold">{{ category }}</h1>
                                                </div>
                                                <div class="border border-b-0 border-lightgrey w-full mr-2.5"></div>
                                            </div>
                                            <div class="flex flex-wrap gap-2 py-2 pr-2">
                                                <div v-for="vendor in vendors" :key="vendor._id" class="flex justify-center items-center p-1 systemWidth h-8 border border-lightgrey rounded cursor-pointer" @click="groupedSelectedVendors(vendor)">
                                                    <div class="w-1/6 flex justify-center">
                                                        <div class="flex justify-center items-center pr-1">
                                                            <img :src="vendor.vendorSrc" alt="Vendor Image" class="w-5 h-5" />
                                                        </div>
                                                    </div>
                                                    <div class="w-5/6 text-xs">{{vendor.vendorName}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="border border-l-0 border-lightgrey h-full"></div>
                                <div class="w-1/2 flex flex-col gap-1">
                                    <h1 class="text-primary text-md">Selected Vendors</h1>
                                    <input class="inputboxstyling placeholderImg" type="text" placeholder="Search Option for Selected Vendors" v-model="searchSelectedVendors"/>
                                    <div class="overflow-y-scroll h-full w-full scrollBarColour">
                                        <div class="flex flex-wrap gap-2 py-2 pr-2">
                                            <div v-for="vendor in selectedVendorsCollectionArray" :key="vendor._id" class="flex justify-center items-center p-1 systemWidth h-8 border border-lightgrey rounded cursor-pointer" :class="vendor.systemIds != undefined ?'bg-lightgrey' : ''" @click="groupedDeselectedVendors(vendor)">
                                                <div class="w-1/6 flex justify-center">
                                                    <div class="flex justify-center items-center pr-1">
                                                        <img :src="vendor.vendorSrc" alt="Vendors Image" class="w-5 h-5" />
                                                    </div>
                                                </div>
                                                <div class="w-5/6 text-xs">{{vendor.vendorName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div v-else-if="selectedTabIndex == 5" class="h-full w-full p-2">
                        <div class="overflow-y-scroll h-full w-full scrollBarColour">
                            <div class="flex flex-wrap gap-2 p-2">
                                <label v-for="framework in frameWorkList" :key= framework.id class=" teamWidth h-24 border border-lightgrey rounded cursor-pointer">
                                    <div class="flex w-full h-full overflow-hidden">
                                        <div class="pl-2 pt-2">
                                            <!-- <input v-if="framework.id == 1" type="checkbox" :checked="true" :disabled="true" class="checkBoxStyles"> -->
                                            <input v-model="selectedFrameWorks" :value="framework" type="checkbox" :disabled="framework.frameworkName == 'InternalControls'" class="checkBoxStyles">
                                        </div>
                                        <div class="p-2 w-full flex flex-col justify-between gap-1">
                                            <div class="flex justify-center h-16 w-14 mx-auto">
                                                <img v-if="framework.imageSrc != ''" :src="framework.imageSrc" alt="framework Image"/>
                                                <img v-else src="../assets/organisationColoured.svg" class="h-16" />
                                            </div>
                                            <div class="flex justify-center w-full text-center text-xs font-bold"><div class="w-1/2" :class="framework.frameworkName.length > 5? 'table-ellipsis': 'table-ellipsis'" @mousemove="toolTip( $event, framework.frameworkName, 'text')"  @mouseout="doFalse()">{{framework.frameworkName}}</div></div>
                                            
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div v-if="toolTipActive">
                                <tool-tip :tipText="toolTipText"></tool-tip>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full flex items-end justify-end buttonContainer gap-4 pt-2.5">
                    <button @click="goBackTab()" class="btnprimary h-7 w-16 flex justify-center items-center taskEditButtons" >Back</button>
                    <button @click="goFrontTab()" class="btnprimary nextButton h-7 w-16 flex justify-center items-center" >Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dynematrix-text-process-enter-active {
  transition: all 0.8s ease-out;
}

.table-ellipsis {
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.dynematrix-text-process-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.dynematrix-text-process-enter-from,
.dynematrix-text-process-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.onBoardBoxes-enter-active,
.onBoardBoxes-leave-active {
  transition: opacity 1s ease-out;
}

.onBoardBoxes-enter-from,
.onBoardBoxes-leave-to {
  opacity: 0;
}

.orgForm-enter-active,
.orgForm-leave-active {
  transition: opacity 3s ease-in;
}

.orgForm-enter-from,
.orgForm-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
    .barLine{
        height: 94%;
    }
}
@media (max-width: 1024px) {
    .barLine{ 
        height: 88%;
    }
}
@media (max-width: 1024px) and (max-height: 500px) {
    .barLine{ 
        height: 103%;
    }
}
@media (max-width: 1024px) and (max-height: 450px) {
    .barLine{ 
        height: 133%;
    }
}
@media (max-width: 1024px) and (max-height: 370px) {
    .barLine{
        height: 190%;
    }
}
@media (max-width: 1024px) and (max-height: 318px) {
    .barLine{ 
        height: 203%;
    }
}
@media (max-width: 1024px) and (max-height: 296px) {
    .barLine{
        height: 278%;
    }
}
@media (max-width: 1024px) and (max-height: 280px) {
    .barLine{
        height: 258%;
    }
}
@media (min-width: 1025px) {
    .barLine{
        height: 94%;
    }
}

.barLine{
    z-index: 0 !important;
    left: 16%;
    top: 50%;
    transform: translateY(-50%); 
}


.checkBoxStyles{
    height: 16px;
    width: 16px;
}
.taskEditButtons {
    background-color: #ffffff;
    border: 1px solid #119BB9;
    color: #119BB9;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.nextButton {
    background-color: #119BB9;
    border: 1px solid #119BB9;
    color: #ffffff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.dataContainer{
    height: 90%;
}
.placeholder-styles {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	padding-top: 5px;
	padding-left: 0.5rem;
	font-size: 14px !important;
	color: #9ca3af;
}
.buttonContainer{
    height: 10%;
    min-height: 30px;
}
::-webkit-scrollbar {
    width: 2px;
}
.paddingContainer{
    padding-left: 10%;
    padding-right: 10%;
}
.infoContainer{
    height: 74%;
}
.presentTabBoxHeight{
    min-height: 45px;
}
@media (max-width: 1024px) {
  .presentTabBoxHeight{
        margin-bottom: 15px;
    }
}
.webkit-scrollbar-thumb{
    width: 10px !important;
}
.scrollBarColour::-webkit-scrollbar,
  .scrollBarColour::-webkit-scrollbar-thumb,
  .scrollBarColour::-webkit-scrollbar-track { 
    width: 4px;
    border: none;
    background: transparent;
  }
.circleBackground {
    background-color: rgba(17, 155, 185, 0.3);
}

.categoryBackground {
    background-color: #e9e9e9;
}
  /* Hides additional scrollbar elements like buttons and corners */
  .scrollBarColour::-webkit-scrollbar-button,
  .scrollBarColour::-webkit-scrollbar-track-piece,
  .scrollBarColour::-webkit-scrollbar-corner,
  .scrollBarColour::-webkit-resizer {
    display: none;
  }

  /* Custom thumb style */
  .scrollBarColour::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #1A5FAC; /* Thumb color */
  }

  /* Custom track with image background */
  .scrollBarColour::-webkit-scrollbar-track {
    background-image: url("../assets/scrollLine.svg");
    background-repeat: repeat-y;
    background-size: contain;
    filter: grayscale(100%) sepia(100%) hue-rotate(180deg) saturate(200%) brightness(80%);
  }

  /* Horizontal rule style */
  hr {
    width: 450px;
    height: 160px;
    border: none;
    margin: 0 auto;
    background-color: #FFFFFF;
  }

.scrollBarColour::-webkit-scrollbar-track {
  border-radius: 6px; /* Rounded corners */
  width: 1px;
}
.scrollBarColour::-webkit-scrollbar-thumb {
  background: #119bb9; /* Darker color for the thumb */
  border-radius: 6px; /* Rounded corners */
  width: 2px;
}
.scrollBarColour::-webkit-scrollbar {
    height: 8px; /* Adjust height of the horizontal scrollbar */
  }
.normalBar{
    position: absolute !important;
    right: 67%;
    top: 130%;
}
.teamWidth{
    width: 32.5%;
}
.uploadedFileNameStyles{
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	color: #4D4D4D;
    font-size: 14px;
}
.systemWidth{
    width: 48.7%;
}

.placeholderImg {
    font-size: 12px;
    padding-left: 30px;
    background: url('../assets/search.svg') no-repeat 10px center; /* Add search icon */
    background-size: 14px;
}
</style>
<script lang="ts">
import { emitter, toast } from '@/main';
import { defineComponent } from 'vue';
import newTable from '@/components/onBoardingTable.vue';
import toolTip from '@/components/toolTip.vue';
import { required, maxLength,requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { email } from '@vuelidate/validators';
import Multiselect from 'vue-multiselect';
const pin = helpers.regex(/^\d{5}(?:\d{1})?$/);
const restrictCharacters30 = helpers.regex(/^.{0,30}$/);
const restrictCharacters60 = helpers.regex(/^.{0,60}$/);
const restrictCharacters5 = helpers.regex(/^.{0,5}$/);
const restrictCharacters300 = helpers.regex(/^.{0,300}$/);
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);
const websiteValidate = helpers.regex(/^(https?:\/\/)?(www\.)?((?!www)[a-zA-Z0-9-]{2,256}\.(?!www)[a-zA-Z]{2,20}(\.(?!www)[a-zA-Z]{2,20}){0,2})(:(?:[0-5]?[0-9]{1,4}|[1-5][0-9]{1,3}|655[0-3][0-5]))?(\/[a-zA-Z0-9\-._~:/#\\]*)?$/g);
const textOnly = helpers.regex(/^[a-zA-Z\s]*$/);
const textwithNumsOnly = helpers.regex(/^[a-zA-Z0-9]*$/);
import _ from 'lodash';
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
    data() :any{
        return {
			v$: useVuelidate(),
            headers:['check', 'activity','team','criticality'],
            pageEnterAnimate:false,
            spinnerLoading: false,
            onBoardTabs:[
                {
                    id:0,
                    name:'organization',
                    displayName:'Organization',
                    isCompleted:false,
                    imageSrc:require('../assets/organisationColoured.svg'),
                },
                {
                    id:1,
                    name:'teams',
                    displayName:'Teams',
                    isCompleted:false,
                    imageSrc:require('../assets/teams.svg'),
                },
                {
                    id:2,
                    name:'activities',
                    displayName:'Activities',
                    isCompleted:false,
                    imageSrc:require('../assets/activities.svg'),
                },
                {
                    id:3,
                    name:'systems',
                    displayName:'Systems',
                    isCompleted:false,
                    imageSrc:require('../assets/monitoring.svg'),
                },
                {
                    id:4,
                    name:'vendors',
                    displayName:'Vendors',
                    isCompleted:false,
                    imageSrc:require('../assets/vendors.svg'),
                },
                {
                    id:5,
                    name:'frameworks',
                    displayName:'Frameworks',
                    isCompleted:false,
                    imageSrc:require('../assets/frameworks.svg'),
                },
 
            ],
            onBoardObject: {
                organizationName:"",
                organizationKey:"",
                websiteUrl:"",
                displayName:"",
                region:"",
                industrySector:"",
                logoName:"",
                logoUrl:"",
                addressLineOne:"",
                addressLineTwo:"",
                city:"",
                state:"",
                country:"",
                pinCode:"",		
                primaryContactName:"",
                primaryContactEmail:"",
                jobTitle:"",
            },
			placeholder: 'Choose a file to upload',
			inputFileName: '',
			fileName:'',
			isInputFileNameValid: true,
			fileToUpload: null,
            countriesList: [],
            citiesList: [],
            stateInfoList: [],
            tipText:'',
            cityList:[{label:'city 1',value:1},{label:'city 2',value:2}],
            stateList:[{label:'city 1',value:1},{label:'city 2',value:2}],
            countryList:[{label:'city 1',value:1},{label:'city 2',value:2}],
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    statesNames:['Telangana','Khammam','Warangal'],
                    CitiesName:['Hyderabad','Bangalore','Chennai']
                },
                {
                    CountryName: 'USA',
                    statesNames:['Alabama','Alaska',' Florida'],
                    CitiesName:['California','Washington','New York']
                },
            ],
            completedTabsIndices:[],
            selectedTabIndex:'',
            selectedTab:{},
            groupedSystemsCollection: [],
            searchTerm:'',
            searchSelectedSystem: '',
            allSelectedSystems: [],
            groupedVendorsCollection: [],
            searchVendor:'',
            searchSelectedVendors: '',
            allSelectedVendors: [],
            toolTipActive:false,
            criticalityData:[
                {
                    id: 1,
                    value: 'Very Low'
                },
                {
                    id: 2,
                    value: 'Low'
                },
                {
                    id: 3,
                    value: 'Moderate'
                },
                {
                    id: 4,
                    value: 'High'
                },
                {
                    id: 5,
                    value: 'Very High'
                }
            ],
            selectedTeamList:[],
            selectedActivitiesList:[],
            teamsList: [],
            systemsList: [],
            vendorsList: [],
            activitiesList:[],
            selectedFrameWorks:[],
            
            progressNumbers:[0,1,2,3,4],

            cacheObj:{},

            frameWorkList:[],
            presentFile:'',
            allTenants:[],
            organizationNameError: false,
            organizationKeyError: false,
            websiteUrlError: false,
            displayNameError: false,
            organisationNamesArray:[],

            latestApiVendors:[],
            dummyVendorsIds:[],

        }
    },
    components: {
        Multiselect,
        newTable,
        toolTip,
        dotsLoader
    },
    validations() {
		return {
        onBoardObject: {
                organizationName: { required,restrictCharacters60 },
                organizationKey: { required,restrictCharacters5,textwithNumsOnly },
                websiteUrl: { required ,restrictCharacters100,websiteValidate},
                displayName: { required,restrictCharacters30 },
                region: { required,restrictCharacters30 },
                industrySector: { required,restrictCharacters30 },
                logoName: { required },
                addressLineOne: { required,restrictCharacters300 },
                addressLineTwo: { restrictCharacters300 },
                city: { required ,restrictCharacters30,textOnly },
                state: { required,restrictCharacters30,textOnly  },
                country: { required ,restrictCharacters30,textOnly },
                pinCode: { required,pin },
                primaryContactName: { required,restrictCharacters30,textOnly  },
                primaryContactEmail: { required , email,restrictCharacters30},
                jobTitle: { required ,restrictCharacters30 }
            },
        }
    },
    computed:{
        completedTabs: function (): any {
            let result;
            result = this.onBoardTabs.filter((obj:any,index:any)=>{
                if(index < this.selectedTabIndex){
                    return true
                }else{
                    return false
                }
            })
            if (this.selectedTabIndex === 3) {
                this.groupBySystemsData();
            } else if(this.selectedTabIndex == 4){
                this.groupByVendorsData();
            }

            return result;
        },
        // Systems
        groupedSystemsCollectionArray: function (): any {
            let searchTerm = this.searchTerm.toLowerCase().trim();
            const filteredSystems:any = {};

            for (let [category, systems] of Object.entries(this.groupedSystemsCollection)) {
                let systemArr: any = [];
                systemArr = systems;

                let filteredCategorySystems = systemArr.filter((system: any) =>{
                    return (system.systemName.toLowerCase().includes(searchTerm))
                });

                let finalFilteredData = filteredCategorySystems.filter((item:any)=>{
                    let ids = this.allSelectedSystems.map((obj:any)=>{return obj._id;})
                    return !ids.includes(item._id)
                    // return !this.allSelectedSystems.includes(item)
                })

                if (finalFilteredData.length > 0) {
                    filteredSystems[category] = finalFilteredData;
                }else{
                    filteredSystems[category] = []
                }
            }
            return filteredSystems;
        },
        selectedSystemsCollectionArray: function (): any {
            if(this.allSelectedSystems && this.allSelectedSystems.length > 0){
                let searchTerm = this.searchSelectedSystem.toLowerCase().trim();
                
                if (!searchTerm) {
                    return this.allSelectedSystems;
                }

                return this.allSelectedSystems.filter((system: any) => 
                    system.systemName.toLowerCase().includes(searchTerm)
                );
            }else{
                return []
            }
        },
        // Vendors
        groupedVendorsCollectionArray: function (): any {
            let searchVendor = this.searchVendor.toLowerCase().trim();
            const filteredVendors:any = {};

            for (let [category, Vendors] of Object.entries(this.groupedVendorsCollection)) {
                let vendorArr: any = [];
                vendorArr = Vendors;

                let filteredCategoryVendors = vendorArr.filter((vendor: any) =>{
                    return (vendor.vendorName.toLowerCase().includes(searchVendor))
                });

                let finalFilteredData = filteredCategoryVendors.filter((item:any)=>{
                    let ids = this.allSelectedVendors.map((obj:any)=>{return obj._id;})
                    return !ids.includes(item._id)
                    // return !this.allSelectedVendors.includes(item)
                })

                if (finalFilteredData.length > 0) {
                    filteredVendors[category] = finalFilteredData;
                }else{
                    filteredVendors[category] = []
                }
            }
            return filteredVendors;
        },
        selectedVendorsCollectionArray: function (): any {
            if(this.allSelectedVendors && this.allSelectedVendors.length > 0){
                let searchVendor = this.searchSelectedVendors.toLowerCase().trim();
                
                if (!searchVendor) {
                    return this.allSelectedVendors;
                }

                return this.allSelectedVendors.filter((vendor: any) => 
                    vendor.vendorName.toLowerCase().includes(searchVendor)
                );
            }else{
                return []
            }
        },
        isDuplicateData: function (): any {
            if(this.allTenants.length != 0){
                return {
                    organizationName: this.allTenants.some((tenant:any) =>{ return tenant.organizationName!= undefined &&  tenant.organizationName.replace(/\s+/g, '').toLowerCase() === this.onBoardObject.organizationName.replace(/\s+/g, '').toLowerCase() }),
                    organizationKey: this.allTenants.some((tenant:any) => { return tenant.organizationKey!= undefined && tenant.organizationKey.replace(/\s+/g, '').toLowerCase() === this.onBoardObject.organizationKey.replace(/\s+/g, '').toLowerCase()}),
                    websiteUrl: this.allTenants.some((tenant:any) =>{ return tenant.websiteUrl!= undefined && tenant.websiteUrl.replace(/\s+/g, '').replace(/^https?:\/\//, '').toLowerCase() === this.onBoardObject.websiteUrl.replace(/\s+/g, '').replace(/^https?:\/\//, '').toLowerCase()}),
                    displayName: this.allTenants.some((tenant:any) =>{ return tenant.displayName!=undefined && tenant.displayName.replace(/\s+/g, '').toLowerCase() === this.onBoardObject.displayName.replace(/\s+/g, '').toLowerCase()}),
                };
            } else {
                return false
            }
        },
    },
    async mounted(){
        if(!Object.keys(this.$route.query).includes('finish')){
            localStorage.removeItem('onBoard');
            localStorage.removeItem('orgLogo');
            localStorage.removeItem('presentTab');
            localStorage.removeItem('tableData');
        }
        
        this.pageEnterAnimate = true;

        await this.getAllSystemsRoot();
        await this.getAllTeamsRoot();
        await this.getAllVendors();
        await this.getAllRootFrameWorks();

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.off('checkedactivity');
        emitter.on('checkedactivity', (data: any) => {
            this.selectedActivitiesList = data
        });

        this.countriesStatesCities.map((obj: any) => {
            this.countriesList.push(obj.CountryName);
        });
    },
    beforeUnmount() {
        localStorage.removeItem('tableData');
    },
    methods:{
        handleOrgFormScroll(event:any) {
            const container = this.$refs.scrollContainerOrg;
            const clickPosition = event.clientY - container.offsetTop;
            const containerHeight = container.clientHeight;
            const scrollHeight = container.scrollHeight;
            const currentScrollPosition = container.scrollTop;

            if (scrollHeight > containerHeight) {
                if (clickPosition > containerHeight * 0.8) {
                    if (currentScrollPosition + containerHeight < scrollHeight) {
                        container.scrollTo({
                            top: currentScrollPosition + containerHeight,
                            behavior: 'smooth'
                        });
                    }
                } else {
                    if (clickPosition < containerHeight * 0.2) {
                        container.scrollTo({
                            top: currentScrollPosition - containerHeight, 
                            behavior: 'smooth'
                        });
                    }
                }
            }
        },
        async goFrontTab(){
            this.buttonType = 'front'
            if(this.selectedTabIndex <= 5 ){
                this.v$.$touch();
                let check:any = !Object.values(this.isDuplicateData).includes(true)
                // && !this.v$.onBoardObject.$invalid && check
                if(this.selectedTabIndex == 0 ){
                    // const formData = {
                    //     onBoardObject: { ...this.onBoardObject },
                    // };

                    let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }
                    
                    localStorage.setItem('onBoard', JSON.stringify(payload));

                    this.selectedTabIndex = this.selectedTabIndex + 1;
                    localStorage.setItem('presentTab', JSON.stringify(this.selectedTabIndex));
                    this.selectedTab = this.onBoardTabs[this.selectedTabIndex];

                }else if(this.selectedTabIndex == 1 && this.selectedTeamList.length > 0){
                    let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }
                    
                    localStorage.setItem('onBoard', JSON.stringify(payload));
                    await this.getActivitiesForSelectedTeams()

                    this.selectedTabIndex = this.selectedTabIndex + 1;
                    localStorage.setItem('presentTab', JSON.stringify(this.selectedTabIndex));
                    this.selectedTab = this.onBoardTabs[this.selectedTabIndex];

                }else if(this.selectedTabIndex == 1 && this.selectedTeamList.length == 0){
                    toast.error('Please Select Atleast One Team', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });

                }else if(this.selectedTabIndex == 2 && this.selectedActivitiesList.length > 0){
                    let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }
                    
                    localStorage.setItem('onBoard', JSON.stringify(payload));
                    this.selectedTabIndex = this.selectedTabIndex + 1;
                    localStorage.setItem('presentTab', JSON.stringify(this.selectedTabIndex));
                    this.selectedTab = this.onBoardTabs[this.selectedTabIndex];
                    localStorage.removeItem('tableData');

                }else if(this.selectedTabIndex == 2 && this.selectedActivitiesList.length == 0){
                    toast.error('Please Select Atleast One Activity', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });

                }else if(this.selectedTabIndex == 3  && this.selectedSystemsCollectionArray.length > 0){
                    
                    let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }
                    
                    localStorage.setItem('onBoard', JSON.stringify(payload));
                    await this.getVendorsForSelectedSystems();

                    // let selectIds:any = this.selectedSystemsCollectionArray.map((obj:any)=>{return obj._id})
                    
                    // let dummyIds = [...this.dummyVendorsIds];
                    // let same = _.isEqual(selectIds,dummyIds)
                    // console.log('same same same same', same);
                    // if(same == false){
                        
                    // }

                    this.dummyVendorsIds = []
                    
                    this.selectedVendorsCollectionArray.map((obj:any)=>{
                        this.dummyVendorsIds.push(obj._id);
                    })

                    this.selectedTabIndex = this.selectedTabIndex + 1;
                    localStorage.setItem('presentTab', JSON.stringify(this.selectedTabIndex));
                    this.selectedTab = this.onBoardTabs[this.selectedTabIndex];

                }else if(this.selectedTabIndex == 3 && this.selectedSystemsCollectionArray.length == 0){
                    toast.error('Please Select Atleast One System', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });

                }else if(this.selectedTabIndex == 4 && this.selectedVendorsCollectionArray.length > 0){
                    let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }
                    
                    localStorage.setItem('onBoard', JSON.stringify(payload));

                    this.selectedTabIndex = this.selectedTabIndex + 1;
                    localStorage.setItem('presentTab', JSON.stringify(this.selectedTabIndex));
                    this.selectedTab = this.onBoardTabs[this.selectedTabIndex];

                    // let b = {
                    //     _id: 1,
                    //     frameworkName: 'Internal Controls',
                    //     imageSrc: "https://crystalnikhil.s3.amazonaws.com/training/c484cfea-7ffe-4b7b-af14-34f1c642eeaf.png"
                    // };

                    let includes = this.selectedFrameWorks.some((obj:any)=>{return obj.frameworkName == 'InternalControls'})

                    if(!includes){
                        let findIC = this.frameWorkList.find((obj:any)=>{return obj.frameworkName == 'InternalControls'})
                        this.selectedFrameWorks.push(findIC)
                    }

                }else if(this.selectedTabIndex == 4 && this.selectedVendorsCollectionArray.length == 0){
                    toast.error('Please Select Atleast One Vendor', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }else if(this.selectedTabIndex == 5 && this.selectedFrameWorks.length > 0){
                    // const selectedFrameworksData = this.selectedFrameWorks.map((framework:any) => ({
                    //         id: framework.id,
                    //         frameworkName: framework.frameworkName
                    //     }));
                    // localStorage.setItem('tabData5', JSON.stringify(this.selectedFrameWorks));

                    let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }

                    localStorage.setItem('onBoard', JSON.stringify(payload));

                    this.$router.push({ name: 'finish-onBoard' });

                }else if(this.selectedTabIndex == 5 && this.selectedFrameWorks.length == 0){
                    toast.error('Please Select Atleast One Framework', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            }
        },
        async goBackTab(){
            this.buttonType = 'back'
            this.v$.$reset();
            if(this.selectedTabIndex > 0){
                this.selectedTabIndex = this.selectedTabIndex - 1;
                localStorage.setItem('presentTab', JSON.stringify(this.selectedTabIndex));
                this.selectedTab = this.onBoardTabs[this.selectedTabIndex];

                if(this.selectedTabIndex == 2){
                    await this.getActivitiesForSelectedTeams()
                }
                if (this.selectedTabIndex == 1){
                    localStorage.setItem('tableData', JSON.stringify(this.activitiesList));
                }
                if(this.selectedTabIndex == 3){
                    let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }
                    
                    localStorage.setItem('onBoard', JSON.stringify(payload));
                    await this.getVendorsForSelectedSystems();
                }
                // if(this.selectedTabIndex == 3){
                //     let a: any = localStorage.getItem('onBoard');
                //     if(a != undefined || a != null){
                //         let qq = JSON.parse(a);
                //         this.allSelectedVendors = qq.detailData[1].values
                //         console.log("qqqqqqqq", this.allSelectedVendors)
                //     }
                // }
            } else if(this.selectedTabIndex == 0){
                localStorage.removeItem('orgLogo')
                this.$router.push({ name: 'start-onBoard' });
            }
            
            
            // else if(this.selectedTabIndex == 3){
            //     // this.buttonType = 'front'
            //     await this.getActivitiesForSelectedTeams()
            // }
           
        },
        async goToTabSelected(id:any){
            this.buttonType = 'back'
            if(id < this.selectedTabIndex){ 
                this.selectedTabIndex = id;
                this.selectedTab = this.onBoardTabs[id]; 
                
                if(this.selectedTabIndex == 2){
                    await this.getActivitiesForSelectedTeams()
                }
            }
        },
        handleFileUpload(event: any) {
            const file = this.$refs.fileInput.files[0];
            if (file) {
                this.fileName = file.name;
                this.fileToUpload = file;
                const fileNameParts = file.name.split('.');
                fileNameParts.pop();
                const nameWithoutExtension = fileNameParts.join('.');
                
                if (this.inputFileName.trim() === '') {
                    this.inputFileName = nameWithoutExtension;
                }
            } else {
                this.fileName = '';
                this.inputFileName = '';
                console.log('inputFileName',this.inputFileName)
            }
        },
		updateFileName(event: any) {
			let file = this.$refs.fileInput.files[0];

			if (file != undefined) {
				this.imgCheck(file);
			}
		},
        resetFileUploads(){
            if(this.$refs.fileInput.value){
                this.$refs.fileInput.value='';
            }
		},
        imgCheck(file: any) {
			if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 2) {
					toast.error('Image should be atmost 2 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads()
				} 
				else {
					this.onBoardObject.logoName = file.name;
                    this.presentFile = file;

                    const reader:any = new FileReader();

                    reader.onloadend = () => {
                        localStorage.setItem('orgLogo',reader.result);
                    };
                    reader.readAsDataURL(file);
				}
			} else {
				toast.error('Supported only jpg, jpeg, png', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.resetFileUploads()
			}
		},
        changeCountry(Country: any) {
            console.log("Country",Country)
            this.citiesList = [];
            let findobj = this.countriesStatesCities.find((obj: any) => {
                return obj.CountryName == Country;
            });
            this.citiesList = findobj.CitiesName;
            this.stateInfoList = findobj.statesNames
            this.onBoardObject.city = '';
        },
        //Systems
        groupBySystemsData(){
            this.groupedSystemsCollection = this.systemsList.reduce((acc: any, system: any) => {
                // If the category doesn't exist, create it and initialize with an empty array
                if (!acc[system.systemCategory]) {
                    acc[system.systemCategory] = [];
                }
                // Push the current system into the correct category array
                acc[system.systemCategory].push(system);
                return acc;
            }, {});
            console.log("groupedSystemsCollection", this.groupedSystemsCollection)
        },
        async groupedSelectedSystems(system: any) {
            this.allSelectedSystems.push(system);
        },
        async groupedDeselectedSystems(system: any) {
            let index = this.allSelectedSystems.findIndex((s: any) => s._id === system._id)
            this.allSelectedSystems.splice(index, 1);
        },
        // Vendors
        groupByVendorsData(){
            this.groupedVendorsCollection = this.vendorsList.reduce((acc: any, vendor: any) => {
                // If the category doesn't exist, create it and initialize with an empty array
                if (!acc[vendor.vendorCategory]) {
                    acc[vendor.vendorCategory] = [];
                }
                // Push the current vendor into the correct category array
                acc[vendor.vendorCategory].push(vendor);
                return acc;
            }, {});
            console.log("groupedVendorsCollection", this.groupedVendorsCollection)
        },
        async groupedSelectedVendors(vendor: any) {
            this.allSelectedVendors.push(vendor);
            let payload:any = {
                        "metadata": [
                            this.onBoardObject
                        ],
                        "detailData": [
                            {
                                "entityId": "10104",
                                "values": this.selectedTeamList
                            },
                            {
                                "entityId": "20006",
                                "values": this.selectedActivitiesList
                            },
                            {
                                "entityId": "20005",
                                "values": this.selectedSystemsCollectionArray
                            },
                            {
                                "entityId": "20003",
                                "values": this.selectedVendorsCollectionArray
                            },
                            {
                                "entityId": "10102",
                                "values": this.selectedFrameWorks
                            }
                        ]
                    }
                    
                    localStorage.setItem('onBoard', JSON.stringify(payload));
        },
        async groupedDeselectedVendors(vendor: any) {
            let index = this.allSelectedVendors.findIndex((s: any) => s._id === vendor._id)
            if(vendor.systemIds == undefined){
                this.allSelectedVendors.splice(index, 1);
                let payload:any = {
                    "metadata": [
                        this.onBoardObject
                    ],
                    "detailData": [
                        {
                            "entityId": "10104",
                            "values": this.selectedTeamList
                        },
                        {
                            "entityId": "20006",
                            "values": this.selectedActivitiesList
                        },
                        {
                            "entityId": "20005",
                            "values": this.selectedSystemsCollectionArray
                        },
                        {
                            "entityId": "20003",
                            "values": this.selectedVendorsCollectionArray
                        },
                        {
                            "entityId": "10102",
                            "values": this.selectedFrameWorks
                        }
                    ]
                }
                
                localStorage.setItem('onBoard', JSON.stringify(payload));
            }
        },
        async getAllSystemsRoot(){
            this.systemsList = [];

            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20005`)
				.then((response: any) => {
                    
                    response.data.map((obj:any)=>{
                        let a = {
                            _id:obj._id,
                            systemName:obj.name,
                            systemCategory:obj.systemCategory,
                            systemSrc:obj.imageUrl,
                        };
                        this.systemsList.push({...a});
                    })
				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        },
        async getAllTeamsRoot(){
            this.teamsList = [];

            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/teams/getall`)
				.then((response: any) => {
                    
                    response.data.map((obj:any)=>{
                        let a = {
                            _id:obj._id,
                            displayName: obj.displayName,
                            teamName: obj.name
                        }
                        this.teamsList.push({...a});
                    })
				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        },
        async getAllVendors(){
            this.vendorsList = [];
            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20003`)
				.then((response: any) => {
                    
                    let arr:any = response.data;

                    arr.map((obj:any)=>{
                       let a = {
                            _id: obj._id,
                            vendorName: obj.name,
                            vendorCategory: obj.vendorCategory,
                            vendorSrc:obj.imageUrl,
                        }
                        this.vendorsList.push(a)
                    })
				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        },
        async getAllRootFrameWorks(){
            this.frameWorkList = [];
            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/framework/getall`)
				.then((response: any) => {
                    let arr:any = response.data;
                    arr.map((obj:any)=>{
                        let a = {
                            _id:obj._id,
                            imageSrc: Object.keys(obj).includes('imageUrl') ? obj.imageUrl : '',
                            frameworkName: Object.keys(obj).includes('controlFrameWork') ? obj.controlFrameWork : obj.name,
                        };
                        // let internalControl= {
                        //     imageSrc: obj.imageUrl,
                        //     frameworkName: 'Internal Controls',
                        // }
                        this.frameWorkList.push(a)
                        // this.frameWorkList['Internal Controls'] = internalControl
                    })

                    // let b = {
                    //     _id: 1,
                    //     frameworkName: 'Internal Controls',
                    //     imageSrc: "https://crystalnikhil.s3.amazonaws.com/training/c484cfea-7ffe-4b7b-af14-34f1c642eeaf.png"
                    // };
                    // this.frameWorkList.push(b)

				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        },
        async getActivitiesForSelectedTeams(){
            this.activitiesList = [];
            this.selectedActivitiesList = []
            this.spinnerLoading = true;
            let payload:any = {"departments":[]};
            this.selectedTeamList.map((obj:any)=>{
                payload.departments.push(obj._id);
            })
            await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/departments/activities/getall`,payload).then((response: any) => {
                response.data.map((obj:any)=>{
                    let teamName = this.teamsList.find((objii: any)=>{
                        return objii._id == obj.departments[0]
                    })
                   
                    let a = {
                        _id:obj._id,
                        activity: obj.name,
                        team: teamName != undefined ? teamName.displayName : 'N/A',
                        criticality: obj.criticality,
                    };

                    this.activitiesList.push({...a});
                    this.spinnerLoading = false;
                })
            })
            .catch((error: any) => {
                this.spinnerLoading = false;
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            });
            this.spinnerLoading = false;
        },
        async getVendorsForSelectedSystems(){
            this.allSelectedVendors = [];
            let payload:any = {"systems":[]};
            this.selectedSystemsCollectionArray.map((obj:any)=>{
                payload.systems.push(obj._id);
            })

            let myResponseValues:any = [];

            await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/system/vendors/getall`,payload).then((response: any) => {
                let result = response.data
                console.log("response.data", result);
                response.data.map((obj:any)=>{
                    let a = {}
                        a = {
                            _id: obj._id,
                            vendorName: obj.name,
                            legalName: obj.legalName,
                            website: obj.website,
                            vendorCategory: obj.vendorCategory,
                            vendorSrc: obj.imageUrl,
                            systemIds: obj.systemIds
                        };
                    myResponseValues.push({...a})
                    
                })

                let existLocalObj:any = localStorage.getItem('onBoard');
                if(existLocalObj != undefined || existLocalObj != null){
                    let qq = JSON.parse(existLocalObj);
                    let existLocalVendors = [...qq.detailData[3].values];

                    let cacheIds:any = []
                    cacheIds = existLocalVendors.map((obj:any)=>{return obj._id})

                    
                    let responseIds:any = myResponseValues.map((obj:any)=>{return obj._id})


                    if(cacheIds.length == 0){
                        this.allSelectedVendors = [...myResponseValues]
                    }else{
                        let presentSystemIds:any = this.selectedSystemsCollectionArray.map((obj:any)=>{return obj._id});
                        let allSelectedArray:any = [];
                        existLocalVendors.map((obj:any)=>{
                            if(Object.keys(obj).includes('systemIds')){
                                let mappedIds:any = obj.systemIds;
                                let result:any = presentSystemIds.some((value:any) =>{return mappedIds.includes(value)});
                                if(result == true){
                                    allSelectedArray.push({...obj})
                                }
                            }else{
                                allSelectedArray.push({...obj})
                            }
                        })
                        myResponseValues.map((obj:any)=>{
                            if(Object.keys(obj).includes('systemIds')){
                                let mappedIds:any = obj.systemIds;
                                let result:any = presentSystemIds.some((value:any) =>{return mappedIds.includes(value)});
                                if(result == true){
                                    allSelectedArray.push({...obj})
                                }
                            }else{
                                allSelectedArray.push({...obj})
                            }
                        })
                        
                        let withSystemIds: any = [];
                        let withoutSystemIds: any = [];
                        let seenIds = new Set();
                        let sysIds = new Set();

                        allSelectedArray.forEach((obj: any) => {
                            if (obj.systemIds && obj.systemIds.length > 0) {
                                if (!sysIds.has(obj._id)) {
                                    sysIds.add(obj._id);
                                    withSystemIds.push(obj);
                                }
                            } else {
                                if (!seenIds.has(obj._id)) {
                                    seenIds.add(obj._id);
                                    withoutSystemIds.push(obj);
                                }
                            }
                        });

                        withoutSystemIds.forEach((obj: any) => {
                            const matchingObj = withSystemIds.find((item: any)=> item._id === obj._id);
                            if (matchingObj) {
                                matchingObj.systemIds = matchingObj.systemIds.concat(obj.systemIds || []);
                            } else {
                                withSystemIds.push(obj);
                            }
                        });

                        console.log("withSystemIds", withSystemIds);

                        // allSelectedArray.forEach((obj: any) => {
                        //     if (!seenIds.has(obj._id)) {
                        //         seenIds.add(obj._id);
                        //         uniqueArray.push(obj);
                        //     }
                        // });
                        console.log("allSelectedArray", allSelectedArray)
                        this.allSelectedVendors = withSystemIds
                    }
                } 

            })
            .catch((error: any) => {
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            });
        },
        
		toolTip( e: any, row: any, status: any) {
			this.tipText = row;
			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			if (container && this.isTextOverflowed(container)) {
				let obj:any = {
					tipText : this.tipText
				}
                emitter.emit('activiteToolTip1',obj);
                this.displayTooltip();
			}
		},
		isTextOverflowed(element: any) {
			return element.scrollWidth != element.clientWidth;
		},
		doFalse() {
            emitter.emit('deActiviteToolTip1');this.displayTooltip();
		},
        displayTooltip(){
            
            emitter.off('activiteToolTip1');
            emitter.on('activiteToolTip1', (data: any) => {                        
                this.toolTipActive = true;
                this.toolTipText = data.tipText
            });
    
            emitter.off('deActiviteToolTip1');
            emitter.on('deActiviteToolTip1', (data: any) => {
                this.toolTipActive = false;
                this.toolTipText = ''
            });
        },
		getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.allTenants = res.data;
			});
            
		},
    },
    async created(){
		await this.getAllTenants();

        if(Object.keys(this.$route.query).includes('finish')){
            this.selectedTabIndex = 5;

            let a:any = localStorage.getItem('onBoard');

            if(a!=null && a!= undefined){
                let b = JSON.parse(a)
                this.cacheObj = b
            }

            if(this.cacheObj.metadata.length > 0){
                this.onBoardObject = this.cacheObj.metadata[0];
            }else{
                this.onBoardObject = {}
            }

                this.selectedFrameWorks = this.cacheObj.detailData[4].values
            // let data1:any = localStorage.getItem('tabData1');
            // if(data1!=null && data1!= undefined){
            //     let b = JSON.parse(data1)
                this.selectedTeamList = this.cacheObj.detailData[0].values;
            // }
            
            // let data2:any = localStorage.getItem('tabData2');
            // if(data2!=null && data2!= undefined){
            //     let b = JSON.parse(data2)
                this.selectedActivitiesList = this.cacheObj.detailData[1].values;
            // }

            // let data3:any = localStorage.getItem('tabData3');
            // if(data3!=null && data3!= undefined){
            //     let b = JSON.parse(data3)
                this.allSelectedSystems = this.cacheObj.detailData[2].values;
            // }

            // let data4:any = localStorage.getItem('tabData4');
            
            // if(data4!=null && data4!= undefined){
            //     let b = JSON.parse(data4)
                this.allSelectedVendors = this.cacheObj.detailData[3].values;
            // }

            // let data5:any = localStorage.getItem('tabData5');
            // if(data5!=null && data5!= undefined){
            //     let b = JSON.parse(data5)
                
                // b.map((obj:any)=>{
                //     let a = this.frameWorkList.find((item:any)=>{return item._id == obj._id;});
                //     this.selectedFrameWorks.push(a)
                // })
            // }
            
        }else{
            this.selectedTabIndex = 0;
        }       
        
        this.selectedTab = this.onBoardTabs[this.selectedTabIndex];

    }
})
</script>