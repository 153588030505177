<template>
    <div class="flex flex-col items-center w-full h-full paddingContainer overflow-y-auto">
        <div class="w-auto mt-3">
            <h1 class="text-primary text-md text-center">Welcome to DYNEMATRIX !</h1>
            <p class="text-primary text-xs text-center mt-1">To get the best experience, we recommend you to complete these onboarding steps</p>
        </div>
 
        <div class="bg-white overflow-y-auto flex flex-col justify-between border mt-3 border-lightgrey rounded infoContainer w-full p-3">
            <div v-for="tab in tabHead" :key="tab.id" @click="openSelectedPopup(tab.id)" class="hover:shadow w-3/4 box min-[h-1/5] border border-lightgrey mb-2 mx-auto rounded-sm cursor-pointer">
                <div class="flex flex-col justify-center items-center h-full">
                    <div class="text-sm font-semibold mb-1">{{ tab.name }}</div>
                    <div class="text-xs text-onBoardBlue">{{ tab.statement }}</div>
                </div>
            </div>
 
            <div class="w-3/4 mx-auto rounded-sm cursor-pointer">
                <div class="w-full flex items-center justify-between buttonContainer">
                    <button @click="goBackButton()" class="btnprimary h-7 w-16 flex justify-center items-center taskEditButtons" >Back</button>
                    <button :disabled="clickOnButton" @click="finishOnBoard()" class="btnprimary nextButton h-7 w-16 flex justify-center items-center" >Finish</button>
                </div>
            </div>
        </div>
    </div>
 
 
    <popup v-if="orgPopup" :popUp="orgPopup">
        <template v-slot:header>
            <div class="flex items-center justify-between popupheadingcontainer rounded">
                <h1>Organization</h1>
                <button
                    class="float-right text-3xl"
                    @click="closePopup()"
                >
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        <fieldset>
            <div>
                <div class="statuscontainer popupbodycontainer">
                    <div class="flex flex-col">
                        <div class="flex w-full mt-3">
                            <div class="w-6/12 flex">
                                <div class="border-l border-t border-b p-2 header w-2/5 border-lightgrey flex items-center">Legal Name </div>
                                <div class="border-b border-t border-l border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.organizationName }}</div>
                            </div>
                            <div class="w-6/12 flex">
                                <div class="border-l border-b border-t p-2 header w-2/5 border-lightgrey flex items-center">Org Key </div>
                                <div class="border border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.organizationKey }}</div>
                            </div>
                        </div>
                        <div class="flex w-full">
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">WebsiteUrl </div>
                                <div class="border-b border-l border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.websiteUrl }}</div>
                            </div>
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">Dispaly Name</div>
                                <div class="border-l border-b border-r border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.displayName }}</div>
                            </div>
                        </div>
                        <div class="flex w-full">
                            <div class="w-6/12 flex">
                                <div class="border-l  border-b p-2 header w-2/5 border-lightgrey flex items-center">Region </div>
                                <div class="border-b  border-l border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.region }}</div>
                            </div>
                            <div class="w-6/12 flex">
                                <div class="border-l border-b  p-2 header w-2/5 border-lightgrey flex items-center">Industry Sector </div>
                                <div class="border-l border-b border-r border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.industrySector }}</div>
                            </div>
                        </div>
                        <div class="flex w-full">
                            <div class="w-full flex">
                                <div class="border-l border-b p-2 header w-1/5 border-lightgrey flex items-center">Logo</div>
                                <div class="border-b border-r border-l border-lightgrey w-4/5 text-xs flex items-center p-2">{{ storedOrgObject.logoName }}</div>
                            </div>
                        </div>
                         <div class="flex w-full">
                            <div class="w-full flex">
                                <div class="border-l  border-b p-2 header w-1/5 border-lightgrey flex items-center">Address-1</div>
                                <div class="border-b border-r  border-l border-lightgrey w-4/5 text-xs flex items-center p-2">{{ storedOrgObject.addressLineOne }}</div>
                            </div>
                        </div>
                         <div class="flex w-full">
                            <div class="w-full flex">
                                <div class="border-l  border-b p-2 header w-1/5 border-lightgrey flex items-center">Address-2</div>
                                <div class="border-b border-r border-l border-lightgrey w-4/5 text-xs flex items-center p-2">{{ storedOrgObject.addressLineTwo }}</div>
                            </div>
                        </div>
                        <div class="flex w-full">
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">City </div>
                                <div class="border-b border-l border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.city }}</div>
                            </div>
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">State </div>
                                <div class="border-l border-b border-r border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.state }}</div>
                            </div>
                        </div>
                        <div class="flex w-full">
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">Country </div>
                                <div class="border-b border-l border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.country }}</div>
                            </div>
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">Pincode </div>
                                <div class="border-l border-b border-r border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.pinCode }}</div>
                            </div>
                        </div>
                        <div class="flex w-full">
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">Primary contact name</div>
                                <div class="border-b border-l border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.primaryContactName }}</div>
                            </div>
                            <div class="w-6/12 flex">
                                <div class="border-l border-b p-2 header w-2/5 border-lightgrey flex items-center">Primary contact email</div>
                                <div class="border-l border-b border-r border-lightgrey w-3/5 text-xs flex items-center p-2">{{ storedOrgObject.primaryContactEmail }}</div>
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
        </fieldset>
    </popup>
 
    <popup v-if="teamPopup" :popUp="teamPopup">
        <template v-slot:header>
            <div class="flex items-center justify-between popupheadingcontainer rounded">
                <h1>Teams</h1>
                <button
                    class="float-right text-3xl"
                    @click="closePopup()"
                >
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        <fieldset>
            <div>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-wrap py-2" id="formContainer">
                        <div v-for="team in storedTeamsList" :key= team.id class="flex m-1 justify-center items-center teamWidth border border-lightgrey rounded">
                            <div class="text-xs">{{team.displayName}}</div>
                        </div>
 
                    </div>
                </div>
            </div>
        </fieldset>
    </popup>
 
    <popup v-if="systemsPopup" :popUp="systemsPopup">
        <template v-slot:header>
            <div class="flex items-center justify-between popupheadingcontainer rounded">
                <h1>Systems</h1>
                <button
                    class="float-right text-3xl"
                    @click="closePopup()"
                >
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        <fieldset>
            <div>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-wrap gap-2 py-4">
                        <div v-for="team in storedSystemsList" :key= team.id class="flex items-center teamWidth h-10 border border-lightgrey rounded px-4">
                            <img v-if="team.imageUrl != ''" :src="team.imageUrl" class="h-4 icon mr-2" />
                            <img v-else src="../assets/organisationColoured.svg" class="h-4 icon mr-2" />
                            <div class="text-xs">{{team.systemName}}</div>
                        </div>
 
                    </div>
                </div>
            </div>
        </fieldset>
    </popup>
 
    <popup v-if="vendorsPopup" :popUp="vendorsPopup">
        <template v-slot:header>
            <div class="flex items-center justify-between popupheadingcontainer rounded">
                <h1>Vendors</h1>
                <button
                    class="float-right text-3xl"
                    @click="closePopup()"
                >
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        <fieldset>
            <div>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-wrap gap-2 py-4">
                        <div v-for="team in storedVendorsList" :key= team.id class="flex items-center teamWidth h-10 border border-lightgrey rounded px-4">
                            <img v-if="team.imageUrl != ''" :src="team.imageUrl" class="h-4 icon mr-2" />
                            <img v-else src="../assets/organisationColoured.svg" class="h-4 icon mr-2" />
                            <div class="text-xs">{{team.name}}</div>
                        </div>
 
                    </div>
                </div>
            </div>
        </fieldset>
    </popup>
 
    <popup v-if="frameWorkPopup" :popUp="frameWorkPopup">
        <template v-slot:header>
            <div class="flex items-center justify-between popupheadingcontainer rounded">
                <h1>Frameworks</h1>
                <button
                    class="float-right text-3xl"
                    @click="closePopup()"
                >
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        <fieldset>
            <div>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-wrap gap-2 py-4">
                        <div v-for="team in storedFrameWorks" :key= team.id class="flex items-center teamWidth h-10 border border-lightgrey rounded px-4">
                            <img v-if="team.imageSrc != ''" :src="team.imageSrc" class="h-4 icon mr-2" />
                            <img v-else src="../assets/organisationColoured.svg" class="h-4 icon mr-2" />
                            <div class="text-xs">{{team.frameWork}}</div>
                        </div>
 
                    </div>
                </div>
            </div>
        </fieldset>
    </popup>
 
    <popup v-if="activitiesPopup" :popUp="activitiesPopup">
        <template v-slot:header>
            <div class="flex items-center justify-between popupheadingcontainer rounded">
                <h1>Activities</h1>
                <button
                    class="float-right text-3xl"
                    @click="closePopup()"
                >
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        <fieldset>
            <div>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer w-full py-2">
                        <div class="tableShow w-full overflow-y-auto">
                            <newTable :tableRowData="storedActivities" :headersData="headers" :openPopup="false" :navigate="false"></newTable>
                            <div v-if="toolTipActive">
                                <tool-tip :tipText="toolTipText"></tool-tip>
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
        </fieldset>
    </popup>
 
</template>
<style scoped>
.tableShow{
    height: 50vh;
}
@media only screen and (min-width: 850px) {
  .box{
    height: 10vh;
}
}
.header{
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1rem;
}
.paddingContainer{
    padding-left: 10%;
    padding-right: 10%;
}
.infoContainer{
    height: 74vh;
}
.taskEditButtons {
    background-color: #ffffff;
    border: 1px solid #119BB9;
    color: #119BB9;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.nextButton {
    background-color: #119BB9;
    border: 1px solid #119BB9;
    color: #ffffff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.teamWidth{
    width: 24%;
    min-height: 40px !important;
}

@media only screen and (min-width: 850px) {
    .box{
        height: 10vh;
    }
}
.tableShow::-webkit-scrollbar {
  width: 2px;
}
 
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import popup from '@/components/popup.vue';
import newTable from '@/components/onBoardingTable.vue';
import toolTip from '@/components/toolTip.vue'
import { emitter, toast } from '@/main';
import { v4 as uuidv4 } from 'uuid';
 
export default defineComponent({
    data() :any{
        return {
            headers:['activity','teamName','criticality'],
            toolTipActive:false,
            toolTipText:'',
            tabHead:[
                { id:1, name:"Organization", statement:"Add details of a Authorized for whole organization",isCompleted: false},
                { id:2, name:"Teams", statement:"Info of the groups that are in that particular organization",isCompleted: false},
                { id:3, name:"Activities", statement:"Flows or process followed by Organization",isCompleted: false},
                { id:4, name:"Systems", statement:"Info of an external entity that provides goods or services",isCompleted: false},
                { id:5, name:"Vendors", statement:"Info of an external entity that provides goods or services",isCompleted: false},
                { id:6, name:"Frameworks", statement:"Frameworks are for managing an organization's information security",isCompleted: false},
            ],
            orgPopup:false,
            teamPopup:false,
            activitiesPopup:false,
            systemsPopup:false,
            vendorsPopup:false,
            frameWorkPopup:false,
            storedOrgObject:{
                organizationName:"",
                organizationKey:"",
                websiteUrl:"",
                displayName:"",
                region:"",
                industrySector:"",
                logoName:"",
                logoUrl:"",
                addressLineOne:"",
                addressLineTwo:"",
                city:"",
                state:"",
                country:"",
                pinCode:"",
                primaryContactName:"",
                primaryContactEmail:"",
            },
            storedTeamsList:[],
            storedSystemsList:[],
            storedVendorsList:[],
            storedFrameWorks:[],
            storedActivities:[],
 
 
            finalOrgObject:{},
            finalTeamsData:[],
            finalActivitiesData:[],
            finalSystemsData:[],
            finalVendorsData:[],
            finalFrameworksData:[],
 
            frameWorkList:[],
            vendorsList:[],
            systemsList:[],
            allActivities:[],
 
            clickOnButton: false,
 
 
            cacheObj:{},

            finalLogoUrl:'',
            incomingFile:''
 
        }
    },
    components: {
        popup,
        toolTip,
        newTable
    },
    methods:{
        goBackButton(){
            let b = this.cacheObj.detailData[1].values;
            localStorage.setItem('tableData', JSON.stringify(b));
            this.$router.push({ name: 'process-onBoard', query:{finish:true} });
        },
        async finishOnBoard(){
            localStorage.removeItem('tableData');
 
            this.clickOnButton = true;
 
            // let a1:any = this.cacheObj.metadata[0];
            // if(a1!=null && a1!= undefined){
            //     let b = JSON.parse(a1)
                this.finalOrgObject = this.cacheObj.metadata[0];
                this.finalTeamsData = [];
                this.finalActivitiesData = [];
                this.finalSystemsData = [];
                this.finalVendorsData = [];
                this.finalFrameworksData = [];

            // }
 
            // let a2:any = localStorage.getItem('tabData1');
            // if(a2!=null && a2!= undefined){
            //     let b = JSON.parse(a2)
                
                
                let team:any = this.cacheObj.detailData[0].values;
                team.map((obj:any)=>{
                    if(obj._id){
                        delete obj._id;
                    }
                    this.finalTeamsData.push(obj)
                })
            // }
 
            // let a3:any = localStorage.getItem('tabData2');
            // if(a3!=null && a3!= undefined){
                let activity = this.cacheObj.detailData[1].values
 
                activity.map((obj:any)=>{
                    let findObj = this.allActivities.find((item:any)=>{
                        return obj._id == item._id
                    })
                    if(findObj != undefined){
                        if(findObj._id){
                            delete findObj._id;
                        }
                        this.finalActivitiesData.push(findObj)
                    }
                });
            // }
 
            // let a4:any = localStorage.getItem('tabData3');
            // if(a4!=null && a4!= undefined){
                let system = this.cacheObj.detailData[2].values
                system.map((obj:any)=>{
                    let findObj = this.systemsList.find((item:any)=>{
                        return obj._id == item._id
                    })
                    if(findObj != undefined){
                        if(findObj._id){
                            delete findObj._id;
                        }
                        this.finalSystemsData.push(findObj)
                    }
                });
            // }
 
            // let a5:any = localStorage.getItem('tabData4');
            // if(a5!=null && a5!= undefined){
                let vendorbusiness = this.cacheObj.detailData[3].values
                vendorbusiness.map((obj:any)=>{
                    let findObj = this.vendorsList.find((item:any)=>{
                        return obj._id == item._id
                    })
                    if(findObj != undefined){
                        if(findObj._id){
                            delete findObj._id;
                        }
                        this.finalVendorsData.push(findObj)
                    }
                });
            // }
 
            // let a6:any = localStorage.getItem('tabData5');
            // if(a6!=null && a6!= undefined){
                let controlCache = this.cacheObj.detailData[4].values
                controlCache.map((obj:any)=>{
 
                    // if(obj.frameworkName == 'Internal Controls'){
                    //     let a = {
                    //         "name": "Internal Controls",
                    //         "description": "The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.",
                    //         "imageUrl": "https://crystalnikhil.s3.amazonaws.com/training/a4295506-04dd-41fc-9077-8af62aecaec2.png"
                    //     }
                    //     this.finalFrameworksData.push(a)
                    // }else{
                        
                        // let findObj = this.frameWorkList.find((item:any)=>{
                        //     return obj._id == item._id
                        // })
                        
                        // if(findObj != undefined){
                        //     if(findObj._id){
                        //         delete findObj._id;
                        //     }
                        if(obj != undefined || obj != null){
                            this.finalFrameworksData.push(obj._id)
                        }

                        // }
                    // }
                   
                });
            // }



            const base64Data:any = localStorage.getItem('orgLogo');
            if(base64Data != undefined){
                const byteCharacters = atob(base64Data.split(',')[1]);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset++) {
                    const byte = byteCharacters.charCodeAt(offset);
                    byteArrays.push(byte);
                }
                const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' }); 
                const file = new File([blob], "image.jpg", { type: 'image/jpeg' });

                this.incomingFile = file;

                await this.uploadFileToS3(this.incomingFile)
            }
            
            this.finalOrgObject.logoUrl = this.finalLogoUrl;
 
            let payload:any = {
                "metadata": [
                    this.finalOrgObject
                ],
                "detailData": [
                    {
                        "entityId": "10104",
                        "values": this.finalTeamsData
                    },
                    {
                        "entityId": "20006",
                        "values": this.finalActivitiesData
                    },
                    {
                        "entityId": "20005",
                        "values": this.finalSystemsData
                    },
                    {
                        "entityId": "20003",
                        "values": this.finalVendorsData
                    },
                    {
                        "entityId": "10102",
                        "values": this.finalFrameworksData
                    }
                ]
            }
 
            console.log('FINAL PAYLOAD',payload);
 
            try{
                await this.$http
                    .post(`${process.env.VUE_APP_ADMIN_API_URL}/organization/create`, payload)
                    .then(async (response: any) => {
                        console.log('FINAL PAYLOAD',payload);
                        let a = ['onBoard','presentTab','orgLogo'];
                        a.map((name:any)=>{localStorage.removeItem(name);})
            
                        this.$router.push({ name: 'complete-onBoard' });
                    });
            }catch {
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }finally {
                this.clickOnButton = false;
            }
           
        },

        openSelectedPopup(id:any){
            if(id == 1){
                this.orgPopup = true
                // let a:any = localStorage.getItem('tabData0');
                // if(a!=null && a!= undefined){
                //     let b = JSON.parse(a)
                //     this.storedOrgObject = b[0]['onBoardObject'];
                // }
                if(this.cacheObj.metadata.length > 0){
                    this.storedOrgObject = this.cacheObj.metadata[0];
                }else{
                    this.storedOrgObject = {}
                }
               
               
            }else if(id == 2){
                this.teamPopup = true;
                // let a:any = localStorage.getItem('tabData1');
 
                // if(a!=null && a!= undefined){
                //     let b = JSON.parse(a)
                //     this.storedTeamsList = b;
                // }
                this.storedTeamsList = this.cacheObj.detailData[0].values;
 
            }else if(id == 3){
                this.storedActivities = []
                this.activitiesPopup = true;
                // let a:any = localStorage.getItem('tabData2');
 
                // if(a!=null && a!= undefined){
                    let b = this.cacheObj.detailData[1].values;
 
                    b.map((obj:any)=>{
                        obj.teamName = obj.team;
                        this.storedActivities.push(obj)
                    })
                // }
 
            }else  if(id == 4){
                this.storedSystemsList = []
                this.systemsPopup = true;
 
                // let a:any = localStorage.getItem('tabData3');
 
                // if(a!=null && a!= undefined){
                    let b = this.cacheObj.detailData[2].values;
 
                    b.map((obj:any)=>{
                        let myObj = {
                            id:obj._id,
                            systemName: obj.systemName,
                            imageUrl:obj.systemSrc
                        }
                        this.storedSystemsList.push(myObj)
                    })
                // }
           
            }else if(id == 5){
                this.storedVendorsList = [];
                this.vendorsPopup = true;
 
                // let a:any = localStorage.getItem('tabData4');
 
                // if(a!=null && a!= undefined){
                    let b = this.cacheObj.detailData[3].values;
 
                    b.map((obj:any)=>{
                        let myObj = {
                            id:obj._id,
                            name: obj.vendorName,
                            imageUrl:obj.vendorSrc
                        }
                        this.storedVendorsList.push(myObj)
                    })
                // }
           
            }else if(id == 6){
                this.storedFrameWorks = []
                this.frameWorkPopup = true
 
                // let a:any = localStorage.getItem('tabData5');
 
                // if(a!=null && a!= undefined){
                    let b = this.cacheObj.detailData[4].values;
 
                    b.map((obj:any)=>{
                        let myObj = {
                            _id:obj._id,
                            frameWork: obj.frameworkName,
                            imageSrc:obj.imageSrc
                        }
                        this.storedFrameWorks.push(myObj)
                    })
                // }
               
            }
        },
        closePopup(){
            this.orgPopup = false
            this.teamPopup = false
            this.activitiesPopup = false
            this.systemsPopup = false
            this.vendorsPopup = false
            this.frameWorkPopup = false
        },

        async getAllSystemsRoot(){
            this.systemsList = [];
 
            await this.$http
                .get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20005`)
                .then((response: any) => {
                    this.systemsList = response.data
                })
                .catch((error: any) => {
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
        },
        async getAllVendors(){
            this.vendorsList = [];
 
            await this.$http
                .get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20003`)
                .then((response: any) => {
                    this.vendorsList = response.data;
                })
                .catch((error: any) => {
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
        },
        async getAllRootFrameWorks(){
            this.frameWorkList = [];
            await this.$http
                .get(`${process.env.VUE_APP_ADMIN_API_URL}/root/framework/getall`)
                .then((response: any) => {
                    this.frameWorkList = response.data;
                })
                .catch((error: any) => {
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
        },
 
        async getAllRootActivities(){
            this.allActivities = [];
            await this.$http
                .get(`${process.env.VUE_APP_ADMIN_API_URL}/root/activities/getall`)
                .then((response: any) => {
                    this.allActivities = response.data;
                })
                .catch((error: any) => {
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
        },

        async uploadImageContent(data: any) {
			console.log("data",data)
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},

        async uploadFileToS3(data: any) {

			const file = await this.uploadImageContent(data);

			let fileExtension = data.name.split('.').pop();
			let id = uuidv4();

			var payload;
			
			payload = { fileData: file, name: `${id}.${fileExtension}` };

			console.log('payload', payload);

			try {
				await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload)
					.then((response: any) => {
						if (response.status == 200) {
							console.log('imageUrlimageUrl', response.data);
                            this.finalLogoUrl = response.data
						} else {
							console.log('response status', response.status);
                            this.finalLogoUrl = ''
						}
					});
			} catch (err) {
				console.error(err);
			}
		},
 
    },
    async mounted(){
 
        let a:any = localStorage.getItem('onBoard');
        if(a!=null && a!= undefined){
            let b = JSON.parse(a)
            this.cacheObj = b
        }else{
            this.cacheObj = {
                "metadata": [],
 
                "detailData": [
                    {
                        "entityId": "10104",
                        "values": []
                    },
                    {
                        "entityId": "20006",
                        "values": []
                    },
                    {
                        "entityId": "20005",
                        "values": []
                    },
                    {
                        "entityId": "20003",
                        "values": []
                    },
                    {
                        "entityId": "10102",
                        "values": []
                    }
                ]
            }
        }
 
        await this.getAllSystemsRoot();
        await this.getAllVendors();
        await this.getAllRootFrameWorks();
        await this.getAllRootActivities();
 
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
 
})
</script>
 