<template>
    <div class="w-full flex justify-center paddingContainer">
        <div class="bg-white w-full border border-lightgrey flex flex-col items-center justify-center rounded infoContainer overflow-y-auto">        
            <div class="flex justify-center items-center topImage">
                <img src="../assets/success.png" class="w-44"/>
            </div>
            <div class="flex justify-center items-center">
                <img src="../assets/Congratulations.svg" class="w-84"/>
            </div>
            <div class="flex justify-center items-center mt-8 mb-6">
                <button @click="goToOrgTab()" class="btnprimary nextButton h-7 w-16 flex justify-center items-center" >Done</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
@media only screen and (max-height: 370px) {
    .topImage{
        margin-top: 120px;
    }
}
.nextButton {
    background-color: #119BB9;
    border: 1px solid #119BB9;
    color: #ffffff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.congratulations {
    font-family: 'Niconne', sans-serif !important;
    font-size: 24px;
    text-align: center; /* Center the text */
  }
  .niconne-regular {
  font-family: "Niconne", cursive;
  font-weight: 400;
  font-style: normal;
}
.infoContainer{
    height: 100%;
}
.paddingContainer{
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 87vh;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    data() :any{
        return {
            name:'aiou',
        }
    },
    methods:{
        goToOrgTab(){
            this.$router.push({ name: 'organisations' });
        }
    },
    // async mounted(){
        // setTimeout(() => {
        //     this.$router.push({ name: 'organisations' });
        // }, 5000); 
    // }
})
</script>
