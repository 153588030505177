<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1 v-if="mainSystem != undefined || mainSystem != null">{{mainSystem.name}}</h1>
                <h1 v-else>System</h1>
            </div>
            <div class="flex items-center">
                <!-- <button v-if="selectedTabIndex == 0" @click="customPopupPopup" class="btnprimary mr-3 flex items-center">Add</button> -->
                
                <button
                    v-if="selectedTabIndex == 1"
                    class="flex"
                    :class="activeControlsListDisplay.length == 0 ? 'btndisabled pointer-events-none':'filterButton'"
                    @click="filtersOpen()"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
                <button
                    v-if="selectedTabIndex == 2"
                    class="flex "
                    :class="allControlsListDisplay.length == 0 ? 'btndisabled pointer-events-none':'filterButton'"
                    @click="filtersOpen()"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>

        <div class="mt-3">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>

        <div class="mt-5 bg-white border border-lightgrey relative rounded">
            <div class="px-5 pb-5 pt-5 w-full h-full">
                <div v-if="screenReduced">
                    <div class="group-buttons" v-if="isSmallScreen">
                        <!-- Group navigation buttons for smaller screens -->
                        <div class="absolute left-0 top-5 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                            <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                        </div>
                        <div class="absolute right-0 top-5 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                            <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                        </div>
                    </div>
                    <div v-else>
                        <!-- Scroll buttons for larger screens -->
                        <div class="absolute left-0 top-5 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                            <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                        </div>
                        <div class="absolute right-0 top-5 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                            <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                        </div>
                    </div>
                </div>
            <!-- <div> -->
                <div>
                    <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                        <div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="progressbarB rounded h-1"></div>
            <!-- </div> -->

            <div class="w-full">
                <div v-if="selectedTabIndex == 0">
                    <div class="py-2 noScrollbar tabStructure1 overflow-y-auto">
                        <div class="formcontainer flex flex-col">
                            <div class="row flex flex-row w-full">
                                <div class="w-6/12">
                                    <label class="controllabel">Name</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="mainSystem.systemName"
                                        placeholder="System Name"
                                        @input="v$.mainSystem.systemName.$touch()"
                                        :class="{
                                            'is-invalid': (v$.mainSystem.systemName.$error || checkSystemName)
                                        }"
                                    />
                                    <div v-if="v$.mainSystem.systemName.$error || checkSystemName" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.systemName.required.$invalid">Required</div>
                                        <div v-else-if="v$.mainSystem.systemName.required && v$.mainSystem.systemName.restrictNames.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        <div v-else-if="checkSystemName" class="invalid-feedback text-xs">System Name already exists.</div>
                                    </div>
                                </div>
                                <div class="w-6/12 leftspacing">
                                    <label class="controllabel">Category</label>
                                    <!-- <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="mainSystem.systemCategory"
                                        placeholder="System Category"
                                        @input="v$.mainSystem.systemCategory.$touch()"
                                        :class="{
                                            'is-invalid': (v$.mainSystem.systemCategory.$error)
                                        }"
                                    /> -->
                                    <multiselect
                                        v-model.trim="mainSystem.systemCategory"
                                        :options="systemCategoryDisplay"
                                        @search-change="asyncSystemCategoryDataFind"
                                        :multiple="false"
                                        :searchable="true"
                                        :hide-selected="true"
                                        open-direction="bottom"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :hideArrow="false"
                                        placeholder="System Category"
                                        deselectLabel=""
                                        :showNoOptions="false"
                                        selectLabel=""
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'has-options': systemCategoryDisplay && systemCategoryDisplay.length > 0,
                                            'no-options': !systemCategoryDisplay.length &&  searchValue !== '',
                                        }"
                                    >
                                    </multiselect>
                                    <div v-if="v$.mainSystem.systemCategory.$error " class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.systemCategory.required.$invalid">Required</div>
                                        <div v-else-if="v$.mainSystem.systemCategory.required && v$.mainSystem.systemCategory.restrictNames.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full upperspacing">
                                <label class="controllabel">Description</label>
                                <textarea
                                    type="text"
                                    class="inputboxstyling"
                                    v-model.trim="mainSystem.systemDescription"
                                    placeholder="Description"
                                    rows="3"
                                    @input="v$.mainSystem.systemDescription.$touch()"
                                    :class="{
                                        'is-invalid': (v$.mainSystem.systemDescription.$error)
                                    }"
                                />
                                <div v-if="v$.mainSystem.systemDescription.$error" class="text-red-500">
                                    <div class="error-text text-xs" v-if="v$.mainSystem.systemDescription.required.$invalid">Required</div>
                                    <div v-else-if="v$.mainSystem.systemDescription.required && v$.mainSystem.systemDescription.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="col-md-6 w-6/12">
                                    <label class="controllabel">Model</label>
                                    <select
                                        v-model="mainSystem.model"
                                        :class="{
                                            'is-invalid': v$.mainSystem.model.$error,
                                            'inputboxstylingForSelectAtPopup': mainSystem.model == '',
                                            'stylingForInputboxstylingForSelectAtPopup': mainSystem.model != ''
                                        }"
                                        >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option class="optionColors" v-for="name in systemModels" :key="name" :value="name">{{name}}</option>
                                    </select>
                                    <div v-if="v$.mainSystem.model.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.model.required.$invalid">Required</div>
                                    </div>
                                </div>

                                <div class="col-md-6 w-6/12 leftspacing">
                                    <label class="controllabel">Add (URL / IP Address / Domain Name)</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="mainSystem.url"
                                        placeholder="Url"
                                        @input="v$.mainSystem.url.$touch()"
                                        :class="{
                                            'is-invalid': (v$.mainSystem.url.$error||checkwebsiteDomain)
                                        }"
                                    />
                                    <div v-if="v$.mainSystem.url.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.url.required.$invalid">Required</div>
                                        <div v-else-if="v$.mainSystem.url.required && v$.mainSystem.url.restrictCharacters100.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        <div v-else-if="v$.mainSystem.url.required &&  v$.mainSystem.url.websiteValidateWithIp.$invalid" class="error-text text-xs">Invalid Website</div>
                                    </div>
                                    <div v-else-if="checkwebsiteDomain" class="text-red-500">
                                        <div class="error-text text-xs">This url already exists</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row flex w-full upperspacing">
                                <div class="col-md-6 w-6/12">
                                    <label class="controllabel">Vendor Category</label>
                                    <multiselect
                                        v-model="mainSystem.vendorCategory"
                                        :options="vendorCategoryDisplayList" 
                                        :searchable="true"  
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :hide-selected="true"
                                        placeholder="Vendor Category"
                                        open-direction="bottom"
                                        @select="getVendorsListBasedOnCategory()"
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed': v$.mainSystem.vendorCategory.$error,
                                            'has-options': vendorCategoryDisplayList && vendorCategoryDisplayList.length > 0,
                                            'no-options': !vendorCategoryDisplayList.length,
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Category Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.mainSystem.vendorCategory.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.vendorCategory.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-6/12 leftspacing">
                                    <label class="controllabel">Vendor</label>
                                    <multiselect
                                        v-model="mainSystem.vendor"
                                        :options="vendorDisplayList" 
                                        :searchable="true"
                                        @search-change="asyncFindVendor"
                                        :disabled="mainSystem.vendorCategory==''"  
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :hide-selected="true"
                                        placeholder="Vendor"
                                        open-direction="bottom"
                                        :multiple="false"
                                        :showNoOptions="false"
                                        :clear-on-select="true"
                                        label="label"
                                        track-by="value"   
                                        :hideArrow="false"
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed': v$.mainSystem.vendor.$error,
                                            'has-options': vendorDisplayList && vendorDisplayList.length > 0,
                                            'no-options': !vendorDisplayList.length &&  searchValue !== '',
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Vendors Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.mainSystem.vendor.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.vendor.required.$invalid">Required</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row w-full upperspacing">
                                <label class="controllabel">Logo</label>
                                <div v-if="mainSystem.imageUrl == '' && !mainSystem._id" :class="{'is-invalid borderRed': v$.fileName.$error,}" class="border w-full rounded py-2  border-lightgrey">
                                    <div class="flex justify-center">
                                        <div>
                                            <div class="flex justify-center">
                                                <label :htmlFor="mainSystem" class="mt-2">
                                                    <img src="@/assets/addImage.svg" alt="" class="w-20 h-20 cursor-pointer" />
                                                </label>
                                                <label :htmlFor="mainSystem">
                                                    <input type="file" :id="mainSystem" style="display: none" ref="fileInput" @change.stop="imageCheckFile('fileInput','add')"/>
                                                </label>
                                            </div>
                                            <div class="flex justify-center items-center mt-2">
                                                <div class="text-xs" v-if="fileName!=''">Selected File :  <span class="text-xs font-bold"> {{fileName}}</span>	</div>
                                                <div v-else-if="fileName == ''" class="text-xs">{{'Click on the icon for uploading a file'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="mainSystem._id && mainSystem.imageUrl !=''" class="border w-full rounded py-2  border-lightgrey">
                                    <div>
                                        <label :htmlFor="mainSystem" class="mt-2 flex justify-center p-2 items-center">
                                            <img :src="mainSystem.imageUrl" alt="" class="w-20 h-20 cursor-pointer" />
                                        </label>
                                        <label :htmlFor="mainSystem" @change.stop="imageCheckFile('fileInput','edit')">
                                            <input type="file" :id="mainSystem" style="display: none" ref="fileInput"/>
                                            <div class="text-xs text-center cursor-pointer">Click on the icon/logo for uploading a file</div>
                                        </label>
                                        
                                    </div>
                                    <div class="flex justify-center items-center mt-2">
                                        <div class="text-xs" v-if="fileName!=''">Selected File :  <span class="text-xs font-bold"> {{fileName}}</span>	</div>
                                    </div>
                                </div>
                                <div v-if="v$.fileName.$error" class="text-red-500">
                                    <div class="error-text text-xs" v-if="v$.fileName.required.$invalid">Required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="selectedTabIndex == 1" class="pt-3 noScrollbar tabStructure1 overflow-y-auto">
                    <!-- {{ sortedDataActiveControls }} -->
                    <div class="tableCornerRadius">
                        <div class="table-container">
                            <table>
                                <thead>
                                    <th class="header cursor-pointer w-4" >
                                        <div class="flex items-center w-4 mx-2">
                                            <input type="checkbox" v-model="selectAllOptionActiveControls" class="checkbox cursor-pointer" :disabled="allControlsList.length == 0" @change="selectAllActiveControls($event)" />
                                        </div>
                                    </th>
                                    <th scope="col" v-for="column in activeControlHeaders" @click="sort(column)" :class="getHeaderProperties0('width',column)" :key="column" class="bg-white capitalize">
                                        <div class="flex items-center" :class="getHeaderProperties0('widthInner',column)">
                                            <div class="pl-5" v-if="column == 'controlPriority'">
                                                {{ 'Priority' }}
                                            </div>
                                            <div v-else>{{ getHeaderProperties0('name',column) }}</div>
                                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <tr v-if="showFilters">
                                        <td></td>
                                        
                                        <td v-for="(name, index) in activeControlHeaders" class="bg-white" :key="index">
                                            <select v-if="name == 'controlPriority'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbPriority" :key="pair.value" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                            <select v-else-if="name == 'controlFrequency'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in controlFrequencyArray" :key="pair.id" :value="pair.id">{{ pair.label }}</option>
                                            </select>

                                            <input v-else type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="allFilters[name]" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-if="sortedDataActiveControls.length == 0">
                                    <tr class="cursor-pointer rowHeight">
                                        <td class="relative h-10" :colspan="activeControlHeaders.length+1">
                                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="nonEmptyTable" v-else>
                                    <tr v-for="(dataObj,index) in sortedDataActiveControls" :key="index" class="cursor-pointer rowHeight">
                                        <td>
                                            <input class="checkbox mx-2" type="checkbox" v-model="checkedActiveControls" @change="changedActiveControlInput()" :value="dataObj._id" />
                                        </td>
                                        <td scope="col" v-for="column in activeControlHeaders" @click="showControlDetailsPopup(dataObj)" @mouseout="doFalse()" :key="column">
                                            
                                            <div v-if="column == 'controlFrequency'">
                                                <div>{{ getFrequencyValue( dataObj['controlFrequency'] ) }}</div>
                                            </div>

                                            <div v-else-if="column == 'controlPriority'">
                                                <div
                                                    class="flex items-center justify-center rounded h-5 w-24"
                                                    :class="colorLabelBg(dataObj[column])"
                                                >
                                                    <div :class="colorLabelText(dataObj[column])">
                                                        {{ getProirityLabel(dataObj[column]) }}
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div v-else>
                                                <div class="table-ellipsis">
                                                    <p class="fullText" @mousemove="toolTip(column, $event, index, dataObj, 'text')">
                                                        {{ dataObj[column] }}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="sortedDataActiveControls.length > 0">
                            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedDataActiveControls"></pagination>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>

                <div v-else-if="selectedTabIndex == 2" class="pt-3 noScrollbar tabStructure1 overflow-y-auto">
                    <div class="tableCornerRadius">
                        <div class="table-container">
                            <table>
                                <thead>
                                    <th class="header cursor-pointer w-4" >
                                        <div class="flex items-center w-4 mx-2">
                                            <input type="checkbox" v-model="selectAllOptionAllControls" class="checkbox cursor-pointer" :disabled="sortedDataAllControls.length == 0" @change="selectAllAllControls($event)" />
                                        </div>
                                    </th>
                                    <th scope="col" v-for="column in allControlHeaders" @click="sort(column)" :class="getHeaderProperties1('width',column)" :key="column" class="bg-white capitalize">
                                        <div class="flex items-center"  :class="getHeaderProperties1('widthInner',column)">
                                            <div class="pl-5" v-if="column == 'controlPriority'">
                                                {{ 'Priority' }}
                                            </div>
                                            <div v-else>{{ getHeaderProperties1('name',column) }}</div>
                                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <tr v-if="showFilters">
                                        <td></td>
                                        
                                        <td v-for="(name, index) in allControlHeaders" class="bg-white" :key="index">
                                            <select v-if="name == 'controlPriority'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbPriority" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                            <select v-else-if="name == 'controlFrequency'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in controlFrequencyArray" :key="pair" :value="pair.id">{{ pair.label }}</option>
                                            </select>

                                            <input v-else type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="allFilters[name]" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-if="sortedDataAllControls.length == 0">
                                    <tr class="cursor-pointer rowHeight">
                                        <td class="relative h-10" :colspan="allControlHeaders.length + 1">
                                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="nonEmptyTable" v-else>
                                    <tr v-for="(controlObject,index) in sortedDataAllControls" :key="index" class="cursor-pointer rowHeight">
                                        <td>
                                            <input class="checkbox mx-2" type="checkbox" v-model="checkedAllControls" @change="changedAllControlInput()" :value="controlObject._id" />
                                        </td>
                                        <td scope="col" v-for="column in allControlHeaders" @mouseout="doFalse()" :key="column">
                                            
                                            <div v-if="column == 'controlFrequency'">
                                                <div>{{ getFrequencyValue( controlObject[column] ) }}</div>
                                            </div>
                                            <div v-else-if="column == 'controlPriority'">
                                                <div
                                                    class="flex items-center justify-center rounded h-5 w-24"
                                                    :class="colorLabelBg(controlObject[column])"
                                                >
                                                    <div :class="colorLabelText(controlObject[column])">
                                                        {{ getProirityLabel(controlObject[column]) }}
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div v-else>
                                                <div class="table-ellipsis">
                                                    <p class="fullText" @mousemove="toolTip(column, $event, index, controlObject, 'text')">
                                                        {{ controlObject[column] }}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="sortedDataAllControls.length > 0">
                            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedDataAllControls"></pagination>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
            </div>
            <div v-if="selectedTabIndex == 0" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm"  style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5">
                    <button :disabled="clickOnButton" :class="JSON.stringify(mainSystem) == JSON.stringify(dummySystem) ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5"  @click="onCancelbtn('cancel')">Cancel</button>
                    <button :disabled="clickOnButton" :class="JSON.stringify(mainSystem) == JSON.stringify(dummySystem) ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary" @click="editSystem('update')" >Save</button>
                </div>
            </div>
            <div v-if="selectedTabIndex == 1" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm"  style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5">
                    <button class="btnprimary mr-1.5" :disabled="clickOnButton" @click="cancelActiveControls()" :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'">Cancel</button>
                    <button class="btnprimary" :disabled="clickOnButton" @click="changeActiveControlsToActivity(10303)" :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'">Save</button>
                </div>
            </div>

            <div v-if="selectedTabIndex == 2" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm"  style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5">
                    <button @click="onCancelAllControls()" :disabled="clickOnButton" :class="checkedAllControls.length == 0 ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary mr-1.5" >Cancel</button>
                    <button @click="mapTheSelectedControlsToActivity(10301)" :disabled="clickOnButton" :class="checkedAllControls.length == 0 ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary">Save</button>
                </div>
            </div>
        </div>
    </div>

    

</template>

<style scoped>
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
table {
  /* table-layout: fixed; */
  /* width: 100%; */
  background: #FFFFFF;
}
.w-40 {
    width: calc(100% - 170px);
}
.w-10 {
    width: calc(100% - 2.5rem);
}
.nonEmptyTable tr:last-child {
  border-bottom: 1px solid #e9e9e9;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.midHead {
    width: 160px !important;
}
.widthForSelect{
    width : 112px !important;
}
.widthofallcontrols{
    width : 10rem !important;
}
.widthofallcontrols1{
    width : 8rem !important;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.optionColors{
   color : #4D4D4D !important;
}
.borderRed {
    border: 1px solid red !important;
}
.tabStructure {
	height: calc(100vh - 105px);
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}

.noScrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

th {
  background-color: #FFFFFF;
  font-family: 'Open Sans' !important;
  cursor: default;
  display: table-cell;
  font-size: 14px !important;
  font-weight: 600;
  height: 60px;
  line-height: 19.5px;
  padding: 0px 10px ;
  /* border-bottom: 1px solid #e9e9e9 !important; */
  text-align: left;
  color: black;
 
}

td {
  font-size: 12px;
  font-weight: 400;
  height: 60px !important;
  line-height: 19.5px;
  padding: 0px 10px ;
  text-align: left;
  text-indent: 0px;
  margin: auto;
  box-sizing: border-box;
  font-family: "Open Sans" !important;
  color: black;
  border-bottom:0px;
}
</style>
<script lang="ts">
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import { emitter, toast } from '@/main';
import store from '@/store';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import breadCrumb from '@/components/breadCrumb.vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
const restrictCharacters = helpers.regex(/^.{0,300}$/);
const restrictNames = helpers.regex(/^.{0,20}$/);
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);
// const websiteValidateWithIp = helpers.regex(/^((http|https):\/\/)?(www\.)+[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/);
const websiteValidateWithIp = helpers.regex(/^(https?:\/\/)?(www\.)?((?!www)[a-zA-Z0-9-]{2,256}\.(?!www)[a-z]{2,20}(\.(?!www)[a-z]{2,20}){0,2}|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))(:(?:[0-5]?[0-9]{1,4}|[1-5][0-9]{1,3}|655[0-3][0-5]))?(\/[a-zA-Z0-9\-._~:/#\\]*)?$/g);

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            selectedTabObj: { displayName: 'Process', name: 'Process', id: 0 },
            carouselHead: [
                { displayName: 'System Info', name: 'activatedControls', id: 0 },
                { displayName: 'Mandatory Controls', name: 'activatedControls', id: 1 },
                { displayName: 'All Controls', name: 'allControls', id: 2 },
            ],
            selectedTabIndex: null,
            showFilters: false,
            sideButton: false,
            clickOnButton: false,
           
            tenants: [],
            tenantsSelect: [],
            orgUsersList: [],
            usersList: [],
            optionsUserArray: [],
            allControlHeaders:['controlNumber','controlTitle','controlFamily','controlClass','controlFrequency','controlPriority'],
            tableProps1:{
                headerNames:[{key:'controlNumber',value:'ID'},{key:'controlTitle',value:'Title'},{key:'controlFamily',value:'Family'},{key:'controlClass',value:'Class'},{key:'controlFrequency',value:'Frequency'},{key:'controlPriority',value:'Priority'}],
                
                headerWidth:[{key:'controlNumber',value:'w-32'},{key:'controlTitle',value:'w-64'},{key:'controlFamily',value:'w-44'},{key:'controlClass',value:'w-48'},{key:'controlFrequency',value:'w-24'},{key:'controlPriority',value:'w-24'}],

                innerHeaderWidth:[{key:'controlNumber',value:'w-14'},{key:'controlTitle',value:'w-36'},{key:'controlFamily',value:'w-36'},{key:'controlClass',value:'w-36'},{key:'controlFrequency',value:'w-28'},{key:'controlPriority',value:'w-28'}],
            },

            activeControlHeaders:['controlNumber','controlTitle','controlFamily','controlClass','controlFrequency','controlPriority'],
            tableProps0:{
                headerNames:[{key:'controlNumber',value:'ID'},{key:'controlTitle',value:'Title'},{key:'controlFamily',value:'Family'},{key:'controlClass',value:'Class'},{key:'controlFrequency',value:'Frequency'},{key:'controlPriority',value:'Priority'}],
                
                headerWidth:[{key:'controlNumber',value:'w-32'},{key:'controlTitle',value:'w-64'},{key:'controlFamily',value:'w-44'},{key:'controlClass',value:'w-48'},{key:'controlFrequency',value:'w-24'},{key:'controlPriority',value:'w-24'}],

                innerHeaderWidth:[{key:'controlNumber',value:'w-14'},{key:'controlTitle',value:'w-36'},{key:'controlFamily',value:'w-36'},{key:'controlClass',value:'w-36'},{key:'controlFrequency',value:'w-28'},{key:'controlPriority',value:'w-28'}],
            },
            
           
            routesArray: [],

            screenReduced : false,
            activitiesData:[],
            allControlsList: [],
            allControlsListDisplay:[],
            systemsArray:[],
            allControlsListDummy:[],
            mainSystem: {
                systemName:'',
                systemCategory:'',
                model:'',
                url:'',
                systemDescription:'',
                imageUrl:'',
                vendor:'',
                vendorCategory:'',
            },
            activeControlsList: [],
            activeControlsListDisplay:[],
            activeControlsListDummy:[],

            checkedAllControls: [],
            selectAllOptionAllControls: false,

            selectAllOptionActiveControls:false,
            checkedActiveControls:[],
            defaultCheckedActiveControls:[],
            systemModels:['IasS','SasS','PasS'],
            vendorDisplayList:[],
            vendorList:[],
            allVendorList:[],
            vendorLabelList:[],
            vendorCategoryDisplayList:['sample1','sample2','sample3'],
            searchValue:'',
            fileName:'',
            dummySystem:{},
            existingSystemNames:[],
            filteredSystemNames:[],
            presentFile:'',
            presentImageUrl:'',

            systemCatgeoryList:[],
            systemCategoryDisplay:[],
            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            pageSize: 20,
            currentPage: 1,
            dynamicPagination: '',
            allFilters: {
                controlPriority:'',
                controlFrequency:''
            },

            controlTypeValues:[
                {
                    id:1,
                    value:'Mandatory'
                },
                {
                    id:2,
                    value:'Optional'
                },
                {
                    id:3,
                    value:'Custom'
                }
            ],

            controlFrequencyArray: [
				{ label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
			],

          
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,

            isSidebarToggle:true,

            customControlObject: {
                controlFrequency:"",
                controlNumber: "",
                controlPriority: "",
                controlTitle: "",
                controlClass: "",
                controlFamily: "",
                controlGuidance: "",
                controlAuditMethodology: "",
                controlAuditSuccessCriteria: "",
                controlMaintenanceTask: "",
                controlPolicies: "",
                controlType: "",
                controlOptionFrameWork: '',
                frameworkControls: '',
            },
            dummyCustomObj:{},
            customPopup:false,
            frameWorksList:[],
            frameJson:{},
            frameworkControls:[],
            filteredControlFamilyOptions:[],
            ControlFamilyArray:[],

            totalOrgControls:[],
            activitiesArray: [],
            teamsArray:[],
            teamsOptions:[],
            allwebsites:[],
            websiteExcludingSelectedWebsite:[],
            criticalityoptions: [
                {
                    id:1,
                    value:'Very Low'
                },
                {
                    id:2,
                    value:'Low'
                },
                {
                    id:3,
                    value:'Moderate'
                },
                {
                    id:4,
                    value:'High'
                },
                {
                    id:5,
                    value:'Very High'
                }
            ],
        };
    },
    components: {
        Multiselect,
        breadCrumb,
        toolTip,
        pagination
    },
    validations() {
        return {
            customControlObject:{
                controlNumber: { required },
                controlTitle: { required },
                controlFrequency: { required },
            },
            mainSystem: {
                systemName:{ required,restrictNames },
                systemCategory:{ required,restrictNames },
                model:{ required },
                url:{ required ,restrictCharacters100,websiteValidateWithIp},
                systemDescription:{ required,restrictCharacters },
                vendor:{ required },
                vendorCategory:{ required },
            },

            fileName:{required}
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbPriority:'dbPriority',
        }),

        sortedDataAllControls: function (): any {
            return [...this.allControlsListDisplay]
                .filter((row: any) => {
                    for (const key in this.allFilters) {
                        const inputValue = this.allFilters[key];
                        if (inputValue !== '' && row[key]) {
                            if (row[key] == 'N/A') {
                                return false;
                            } 
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },

        sortedDataActiveControls: function (): any {
            return [...this.activeControlsListDisplay]
                .filter((row: any) => {
                    for (const key in this.allFilters) {
                        const inputValue = this.allFilters[key];
                        if (inputValue !== '' && row[key]) {
                            if (row[key] == 'N/A') {
                                return false;
                            } 
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },

        disableStatus: function (): any {
			let disableStatusCondition;

            if(this.customControlObject._id){
                if (JSON.stringify(this.customControlObject) == JSON.stringify(this.dummyCustomObj)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
            }else{
                let val: any = [];
                Object.values(this.customControlObject).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }

			return disableStatusCondition;
		},

        arraysAreEqual: function (): any {
            const sortedArray1 = [...this.checkedActiveControls].sort();
            const sortedArray2 = [...this.defaultCheckedActiveControls].sort();
            
            if (sortedArray1.length !== sortedArray2.length) {
                return false;
            }

            for (let i = 0; i < sortedArray1.length; i++) {
                if (sortedArray1[i] !== sortedArray2[i]) {
                return false;
                }
            }

            return true;
        },
        
        checkSystemName: function (): any {
            const trimmedSystemName = this.mainSystem.systemName.replace(/\s+/g, '').trim().toLowerCase();
 
            if(this.existingSystemNames == null || this.existingSystemNames == undefined || this.existingSystemNames.length == 0){
                return false
            }
             
            if(this.mainSystem._id){
                let a = false;
                a = this.filteredSystemNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedSystemName);
                return a
               
            }else{
                let a = false;
                a = this.existingSystemNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedSystemName);
                return a
            }
        },
        checkwebsiteDomain: function (): any {
            const trimmedSystemurl = this.mainSystem.url.trim().toLowerCase();

            if(this.allwebsites == null || this.allwebsites == undefined || this.allwebsites.length == 0){
                return false
            }
             
            if(this.mainSystem._id){
                let a = false;
                a = this.websiteExcludingSelectedWebsite.map((name: any) => name.toLowerCase()).includes(trimmedSystemurl);
                return a
                
            }else{
                let a = false;
                a = this.allwebsites.map((name: any) => name.toLowerCase()).includes(trimmedSystemurl);
                return a
            }
        }
    },
    methods: {
        getFrameworkControls(name:any){
            this.frameworkControls=[];
            let array=Object.values(this.frameJson)
            array.forEach((x:any)=>{
                x.forEach((y:any)=>{
                if(y.controlFrameWork == name){
                    this.frameworkControls.push(`${y.controlNumber}` + `--` + `${y.controlTitle }`);
                }
                })
            })
		},
        showControlDetailsPopup(data:any){
            this.customPopup = true;
            this.customControlObject = {...data};
            this.dummyCustomObj = {...data}
            this.getFrameworkControls(data.controlOptionFrameWork)
        },
        cancelActiveControls(){
            this.checkedActiveControls = [...this.defaultCheckedActiveControls];
            if(this.checkedActiveControls.length == this.activeControlsList.length){
                this.selectAllOptionActiveControls = true;
            }else{
                this.selectAllOptionActiveControls = false;
            }
        },
        saveActivityInfo(){
            console.log("fdgdfg")
        },
        cancelActivityInfo(){
            console.log("fdgdfg")
        },
        asyncFindControlFamily(query:any) {
            query = query.trim();
            if (query == '') {
                    this.filteredControlFamilyOptions = [];
            } else {
                this.filteredControlFamilyOptions = this.ControlFamilyArray.filter((item:any) => 
                    item?.toLowerCase().includes(query.toLowerCase())
                );
                let isNewOption = !this.ControlFamilyArray.some((item:any) => 
                    item?.toLowerCase() === query.toLowerCase()
                );
                if (isNewOption && query !== '') {
                    this.filteredControlFamilyOptions.push(query);
                }
            }
        },
        onCancelbtnCustom(prop:any){
            this.v$.$reset();
            
            if (prop === 'close') {
                this.customPopup = false;
                this.dummyCustomObj = {}
			}

            if(this.customControlObject._id){
                this.customControlObject = {...this.dummyCustomObj}
            }else{
                Object.keys(this.customControlObject).forEach((prop) => {
                Array.isArray(this.customControlObject[prop])
                    ? (this.customControlObject[prop] = [])
                    : (this.customControlObject[prop] = "");
                });
            }
            
        },
        onCancelAllControls(){
            this.checkedAllControls = [];
            this.selectAllOptionAllControls = false;
        },
        colorLabelText(value: any) {
            if (value == 1) {
                return ["text-lightgreen font-semibold"];
            } else if (value == 2) {
                return ["text-green font-semibold"];
            } else if (value == 3) {
                return ["text-yellow font-semibold"];
            } else if (value == 4) {
                return ["text-error font-semibold"];
            } else if (value == 5) {
                return ["text-darkred font-semibold"];
            }
        },
        colorLabelBg(value: any) {
            if (value == 1) {
                return ["bg-veryLowColor border-solid border border-lightgreen"];
            } else if (value == 2) {
                return ["bg-lowColor border-solid border border-green"];
            } else if (value == 3) {
                return ["bg-moderateColor border-solid border border-yellow"];
            } else if (value == 4) {
                return ["bg-highColor border-solid border border-error"];
            } else if (value == 5) {
                return ["bg-veryHighColor border-solid border border-darkred"];
            }
        },
        getProirityLabel(val:any){
            if(val == 'N/A'){
                return 'N/A'
            }else{
                let a = this.dbPriority.find((obj:any)=>{return obj.value == val})
                return a.key
            }
        },
        
        getVendorsListBasedOnCategory(){
            this.vendorList = [];
            this.allVendorList.map((obj:any)=>{
                if(obj.vendorCategory == this.mainSystem.vendorCategory){
                    let a = {value:obj._id,label:obj.name};
                    this.vendorList.push(a)
                }
            })

            if(this.mainSystem._id){
                this.mainSystem.vendor = ''
            }
        },
        filtersOpen(){
             this.showFilters = !this.showFilters;
                if (this.showFilters == true) {
                    this.allControlsListDummy = this.allControlsListDisplay;
                } else {
                    this.allControlsListDisplay = this.allControlsListDummy;
                    for (const key in this.allFilters) {
                        this.allFilters[key] = '';
                    }
                }
        },
        getHeaderProperties1(prop:any,columnName:any){
            if(prop=='name'){
                let a = this.tableProps1.headerNames.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return columnName
                }

            }else if(prop=='width'){
                let a = this.tableProps1.headerWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }else if(prop=='widthInner'){
                let a = this.tableProps1.innerHeaderWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
        },

        getHeaderProperties0(prop:any,columnName:any){
            if(prop=='name'){
                let a = this.tableProps0.headerNames.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return columnName
                }

            }else if(prop=='width'){
                let a = this.tableProps0.headerWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }else if(prop=='widthInner'){
                let a = this.tableProps0.innerHeaderWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        getHeaderAlignment(column:any){
            // {key:'clientUniqueId',value:'CCID'},{key:'controlTitle',value:'Title'},{key:'createdAt',value:'Activated At'},{key:'controlFrequency',value:'Frequency'},{key:'classificationType',value:'Type'},{key:'controlPriority',value:'Priority'}
            if(column == 'clientUniqueId'){
                return 'w-24'
            } else if(column == 'controlTitle'){
                return 'w-24'
            } else if(column == 'controlFrequency'){
                return 'w-28'
            } else if(column == 'controlPriority'){
                return 'w-28'
            } else{
                return ''
            }
            

        },
        paginationUpdations(currentPage: any, pageSize: any) {
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        selectAllAllControls(e: any) {
            this.checkedAllControls = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.allControlsList) {
                    this.checkedAllControls.push(obj._id);
                }
            } else {
                this.checkedAllControls = [];
            }
        },
        asyncSystemCategoryDataFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.systemCategoryDisplay = [];
			} else {
				this.systemCategoryDisplay = this.systemCatgeoryList.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.systemCatgeoryList.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.systemCategoryDisplay.push(query);
				}
			}
		},
        asyncFindVendor(query:any){
            this.searchValue = query
			if(query == ''){
				this.vendorDisplayList = []
			}else if(query.length > 0){
				this.vendorDisplayList = this.vendorList.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
        },
        selectAllActiveControls(e:any){
            this.checkedActiveControls = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.activeControlsList) {
                    this.checkedActiveControls.push(obj._id);
                }
            } else {
                this.checkedActiveControls = [];
            }
        },
        changedAllControlInput(){
            if(this.checkedAllControls.length == this.allControlsList.length && this.allControlsList.length != 0){
                this.selectAllOptionAllControls = true;
            }else{
                 this.selectAllOptionAllControls = false;
            }
        },
        changedActiveControlInput(){
            if(this.checkedActiveControls.length == this.activeControlsList.length  && this.activeControlsList.length != 0){
                this.selectAllOptionActiveControls = true;
            }else{
                 this.selectAllOptionActiveControls = false;
            }
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.toolTipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            // switch (status) {
            //     case 'text':
            this.handleTextTooltip(e);
            //         break;
            // }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.toolTipText
                }

                emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        async toggleTabs(tabId: any) {
            this.dataLoading = true
            if (tabId.id == 1) {
                this.currentPage = 1
                this.filtersOpen();
                emitter.emit('visible', false);
                this.showFilters = false;
                await this.getActiveControlsForSystem();
            } else if (tabId.id == 2) {
                this.currentPage = 1
                this.filtersOpen();
                emitter.emit('visible', false);
                this.showFilters = false;
                await this.getAllControls();
            }

            this.selectedTabIndex = tabId.id;
            sessionStorage.setItem('selectedTab', tabId.id);
            this.selectedTabObj = tabId;
            console.log("jhygfd",)
            
            this.dataLoading = false
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.usersList = res.data;
                this.orgUsersList = res.data;
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        getControlTypeValue(a:any){
            let val = this.controlTypeValues.find((item:any)=>{ return item.id == a });
            if(val != undefined){
                return val.value
            }else{
                return 'N/A'
            }
        },

        getFrequencyValue(a:any){
            let val = this.controlFrequencyArray.find((item:any)=>{ return item.id == a });
            if(val != undefined){
                return val.label
            }else{
                return 'N/A'
            }
        },

        async getAllControls(){
        
            this.allControlsListDisplay = [];
            this.allControlsListDummy = [];
            this.ControlFamilyArray = []
            // /root/system/:systemId/allcontrols/get
            let systemId:any = this.$route.params.systemId
            try{
                await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/system/${systemId}/allcontrols/get`).then((result: { data: any }) => {
                    this.allControlsList = [...result.data];

                    result.data.map((obj:any)=>{
                        let a = {
                            "_id":obj._id,
                            "controlNumber": obj.controlNumber == ''  || obj.controlNumber == undefined? 'N/A': obj.controlNumber,
                            'controlTitle': obj.controlTitle == '' || obj.controlTitle == undefined ? 'N/A': obj.controlTitle,
                            'controlFamily': obj.controlFamily == '' || obj.controlFamily == undefined ? 'N/A': obj.controlFamily,
                            'controlClass': obj.controlClass == ''  || obj.controlClass == undefined? 'N/A': obj.controlClass,
                            'controlFrequency': obj.controlFrequency == '' || obj.controlFrequency == undefined ? 'N/A': obj.controlFrequency,
                            'controlPriority': (isNaN(obj.controlPriority) || obj.controlPriority === '') ? 'N/A' : obj.controlPriority
                        }

                        if (Object.keys(obj).includes('controlFamily') && (obj.controlFamily != "" || obj.controlFamily != null || obj.controlFamily != undefined) && !this.ControlFamilyArray.includes(obj.controlFamily)) {
                            this.ControlFamilyArray.push(obj.controlFamily);
                        }

                        this.allControlsListDisplay.push({...a});
                        this.allControlsListDummy.push({...a})
                    })


                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }

        },

        async getActiveControlsForSystem(){
            this.activeControlsList = []
            this.activeControlsListDisplay = []
            this.activeControlsListDummy = [];
            this.defaultCheckedActiveControls = [];
            this.checkedActiveControls = []
            // route:/root/system/:systemId/mappedcontrol/get
            let systemId:any = this.$route.params.systemId
            console.log("dfgdfgdfg",systemId)
            try{
                await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/system/${systemId}/mappedcontrol/get`).then((result: { data: any }) => {
                    console.log("dfgdfgdfg",result.data)
                    this.allControlsList = [...result.data];
                    result.data.map((obj:any)=>{
                        let a = {
                            "_id":obj._id,
                            "controlNumber": obj.controlNumber == '' || obj.controlNumber == undefined? 'N/A': obj.controlNumber,
                            'controlTitle': obj.controlTitle == '' || obj.controlTitle == undefined? 'N/A': obj.controlTitle,
                            'controlFamily': obj.controlFamily == '' || obj.controlFamily== undefined? 'N/A': obj.controlFamily,
                            'controlClass': obj.controlClass == '' || obj.controlClass == undefined? 'N/A': obj.controlClass,
                            'controlFrequency': obj.controlFrequency == ''|| obj.controlFrequency == undefined? 'N/A': obj.controlFrequency,
                            'controlPriority': (isNaN(obj.controlPriority) || obj.controlPriority === '') ? 'N/A' : obj.controlPriority
                        }

                        if (Object.keys(obj).includes('controlFamily') && (obj.controlFamily != "" || obj.controlFamily != null || obj.controlFamily != undefined) && !this.ControlFamilyArray.includes(obj.controlFamily)) {
                            this.ControlFamilyArray.push(obj.controlFamily);
                        }

                        this.activeControlsListDisplay.push({...a});
                        this.activeControlsListDummy.push({...a})
                        // this.activeControlsList.push({...a})
                        // this.checkedActiveControls.push({...a})
                        console.log("this.jlkfdg",this.activeControlsListDisplay)
                    })


                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            // let dummy:any = [];

            this.activeControlsListDisplay.map((obj:any,index:any)=>{
            //     obj.classificationType = 1;
            //     obj.createdAt = this.getDateFormat(new Date());
                // if(index == 0){
                    this.checkedActiveControls.push(obj._id)
                    this.defaultCheckedActiveControls.push(obj._id)
                // }
            })
           

            this.activeControlsList = [...this.activeControlsListDisplay]
            this.activeControlsListDisplay = [...this.activeControlsListDisplay];
            this.activeControlsListDummy = [...this.activeControlsListDisplay];

            if(this.checkedActiveControls.length == this.activeControlsList.length && this.activeControlsList.length!=0){
                this.selectAllOptionActiveControls = true;
            }else{
                this.selectAllOptionActiveControls = false;
            }
            
        },
        getDateFormat(date: any) {
            if (date == "") {
                return "None";
            } else {
                let now = moment(date);
                return now.format("DD-MM-YYYY");
            }
        },

        // async getAllControls(frameName: any) {
        //     this.totalOrgControls = [];

		// 	try {
		// 		const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`);
				
		// 		this.totalOrgControls = result.data;

		// 		let arr: any = [];

        //         if((this.totalOrgControls != null || this.totalOrgControls != undefined) && this.totalOrgControls.length != 0){
        //             this.totalOrgControls.forEach((item: any) => {
        //                 if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !Object.keys(item).includes('isInternalControl')) {
        //                     arr.push(item);
        //                     this.frameJson = this.groupByFrameworks(arr);
        //                 }
        //             });
                    
        //             let displayFrameName = '';	
        //             if(this.frameJson != undefined){
        //                 Object.keys(this.frameJson).forEach((key, index) => {
        //                     if (frameName === index) {
        //                         displayFrameName = key;
        //                     }
        //                     this.frameWorksList.push(key);
        //                 });
        //             }
        //         }
		// 	} catch (e) {
		// 		toast.error(`Something went wrong`, {
		// 			timeout: 1000,
		// 			closeOnClick: true,
		// 			closeButton: 'button',
		// 			icon: true
		// 		});
		// 	}
		// },
        groupByFrameworks(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
        
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
       
        scrollTabs(direction:any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs('tab');
        },
 
        handleResize() {
            const width = window.innerWidth;
            if (width <= 427) {
                this.tabsPerScreen = 1;
            } else if (width <= 569) {  
                this.tabsPerScreen = 3;
            } else if (width <= 1024) {
                this.tabsPerScreen = 5;
            } else {
                this.tabsPerScreen = this.carouselHead.length; // Show all tabs for larger screens
            }

            this.isSmallScreen = (width <= 1024); // For logic buttons ref

            // Calculate total groups
            this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerScreen);

            // Determine the group containing the selected tab
            this.updateCurrentGroupIndex();
            this.updateVisibleTabs('normal');
            this.updateButtonStates();
        },

        updateCurrentGroupIndex() {
            const selectedTabIndex = this.carouselHead.findIndex((tab:any) => tab.id === this.selectedTabIndex);
            if (selectedTabIndex >= 0) {
                this.currentGroupIndex = Math.floor(selectedTabIndex / this.tabsPerScreen);
            } else {
                this.currentGroupIndex = 0; // Reset to first group if selected tab not found
            }
        },

        updateVisibleTabs(from:any) {
            if (this.tabsPerScreen === this.carouselHead.length) {
                this.visibleTabs = [...this.carouselHead]; // Show all tabs
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerScreen;
                const endIdx = startIdx + this.tabsPerScreen;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
            }

            if(from=='tab'){
                this.toggleTabs(this.visibleTabs[0])
            }
        },


        async mapTheSelectedControlsToActivity(type:any){
            this.clickOnButton = true;
            let systemId:any = this.$route.params.systemId
            let payload:any = {
                mappedControls :  this.checkedAllControls
            }
            try{
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/system/${systemId}/${type}/contolsmapping`,payload).then((res: any) => {
                    toast.info('Updated Sucessfully', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                })
            }catch(e:any){
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    })
            }finally{
                this.clickOnButton = false 
            }
            await this.getAllControls();
            this.checkedAllControls = []
            this.selectAllOptionAllControls = false;
        },

        async changeActiveControlsToActivity(type:any){

            this.clickOnButton = true;

            let obj:any = []
            this.defaultCheckedActiveControls.forEach((id:any) => {
                if (!this.checkedActiveControls.includes(id)) {
                obj.push(id);
                }
            });
            console.log('CHANGEEEEE',obj)
            
            let systemId:any = this.$route.params.systemId
            let payload:any = {
                mappedControls :  obj
            }
            try{
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/system/${systemId}/${type}/contolsmapping`,payload).then((res: any) => {
                    toast.info('Updated Sucessfully', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                })
            }catch(e:any){
                    toast.error('Error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    })
            }finally{
                this.clickOnButton = false 
            }
            await this.getActiveControlsForSystem();
        },
        
        
        
        async editSystem(type:any){
            this.v$.mainSystem.$touch();

            if (!this.v$.mainSystem.$invalid  && this.checkSystemName == false && this.checkwebsiteDomain == false) {

                let presentId = this.mainSystem._id;
                
                let payload:any = {
                    "name":this.mainSystem.systemName,
                    "systemCategory":this.mainSystem.systemCategory,
                    "description":this.mainSystem.systemDescription,
                    "model":this.mainSystem.model,
                    "url":this.mainSystem.url,
                    "vendorCategory":this.mainSystem.vendorCategory,
                    "vendor":this.mainSystem.vendor.value,
                    "imageUrl": this.mainSystem.imageUrl
                }

                if(Object.keys(this.mainSystem).includes('imageChanged')){
                    await this.uploadFileToS3(this.presentFile);

                    payload.imageUrl = this.presentImageUrl;
                }

                await this.updatePresentSystem(payload,presentId);
                this.dummySystem = {...this.mainSystem};
                
            }else{
                console.log('this.v$.system.$invalid',!this.v$.mainSystem.$invalid)
                console.log('this.checkSystemName == false',this.checkSystemName == false)
            }

        },

        async updatePresentSystem(payload:any,presentId:any){
            this.clickOnButton = true;

            try{
                await this.$http
				.post(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/update/${presentId}/20005`, payload)
				.then(async (response: any) => {
                    this.presentFile = '';
                    this.presentImageUrl  = '';
                    // this.onCancelbtn('close')
                    await this.getAllSystemsRoot()
					toast.info('Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});	
				})
            }catch {
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }finally {
                this.clickOnButton = false;
            }
        },
        async uploadImageContent(data: any) {
			console.log("data",data)
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},

        async uploadFileToS3(data: any) {
            this.dataLoading = true;
            this.showSystemPopup = false
			
			const file = await this.uploadImageContent(data);

			let fileExtension = data.name.split('.').pop();
			let id = uuidv4();

			var payload;
			
			payload = { fileData: file, name: `${id}.${fileExtension}` };

			console.log('payload', payload);

			try {
				await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload)
					.then((response: any) => {
						if (response.status == 200) {
							console.log('imageUrlimageUrl', response.data);
                            this.presentImageUrl = response.data;
                            this.dataLoading = false;
						} else {
							console.log('response status', response.status);
                            this.presentImageUrl = ''
						}
					});
			} catch (err) {
				console.error(err);
			}
		},
        
        imageCheckFile(fileInput:any,type:any) {
			let file = [];
			this.fileName = '';
			
			file = this.$refs[fileInput].files[0];

            console.log('myfile',file)
			
			if (file != undefined) {
				this.imgCheck(file,type);
			}
			
		},
        imgCheck(file: any,type:any) {
			if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Image should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads()
				} 
				else {
					this.fileName = file.name;
                    this.presentFile = file;

                    // if(type == 'edit'){
                        this.mainSystem.imageChanged = true
                    // }
				}
			} else {
				toast.error('Supported only jpg, jpeg, png', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.resetFileUploads()
			}
		},
        resetFileUploads(){
            if(this.$refs.fileInput.value){
                this.$refs.fileInput.value='';
            }
		},
        onCancelbtn(prop:any){
            this.v$.$reset();
            if(prop == 'close'){
                this.showSystemPopup  = false;
                this.fileName = ''
            }

            if(this.fileName != ''){
                this.fileName = ''
            }
            this.mainSystem = {...this.dummySystem};
        },
        async getAllVendors(){
            this.vendorCategoryDisplayList = [];
            this.vendorList = [];
            this.vendorLabelList = []

            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20003`)
				.then((response: any) => {
                    
                    let arr:any = response.data;

                    this.allVendorList = arr;

                    arr.map((item:any)=>{
                        this.vendorCategoryDisplayList.push(item.vendorCategory);
                        let a = {value:item._id,label:item.name};
                        this.vendorLabelList.push(a)
                    })
				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        },
        async getAllSystemsRoot(){
            this.allwebsites=[];
            this.websiteExcludingSelectedWebsite=[]
            this.existingSystemNames = [];
            this.systemsArray = [];
            this.dummyList = [];
            this.systemCatgeoryList = [];

            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20005`)
				.then((response: any) => {
                    this.systemsArray = response.data
                    // response.data.map((obj:any)=>{
                    //     let b = this.vendorLabelList.find((item:any)=>{return item.value == obj.vendor})
                    //     let a = {
                    //         _id:obj._id,
                    //         systemName:obj.name,
                    //         systemCategory:obj.systemCategory,
                    //         model:obj.model,
                    //         url:obj.url,
                    //         systemDescription:obj.description,
                    //         imageUrl:obj.imageUrl,
                    //         vendor: b==undefined ? 'N/A' : b,
                    //         vendorName: b==undefined ? 'N/A' : b.label ,
                    //         vendorCategory:obj.vendorCategory,
                    //     };
                    //     if(!this.systemCatgeoryList.includes(obj.systemCategory)){
                    //         this.systemCatgeoryList.push(obj.systemCategory)
                    //     }
                    //     this.systemsArray.push({...a});
                    //     this.dummyList.push({...a})
                    // })

                    this.systemsArray.map((obj:any)=>{
                        this.existingSystemNames.push(obj.name)
                        this.allwebsites.push(obj.url)
                        if(!this.systemCatgeoryList.includes(obj.systemCategory)){
                            this.systemCatgeoryList.push(obj.systemCategory)
                        }
                    })
                    
            // mainSystem: {
            //     systemName:'',
            //     systemCategory:'',
            //     model:'',
            //     url:'',
            //     systemDescription:'',
            //     imageUrl:'',
            //     vendor:'',
            //     vendorCategory:'',
            // },
                    let systemId:any = this.$route.params.systemId
                    let systemObj:any = this.systemsArray.find((obj:any)=> {return obj._id == systemId})
                    systemObj.systemName = systemObj.name
                    systemObj.systemDescription =  systemObj.description
                    let data:any = this.vendorLabelList.find((obj:any)=>{return obj.value == systemObj.vendor})
                    systemObj.vendor = data
                    this.mainSystem = {...systemObj}
                    this.presentImageUrl = this.mainSystem.imageUrl
                    this.filteredSystemNames = this.existingSystemNames.filter((name:any)=>{
                        return name != this.mainSystem.systemName
                    })
                    this.websiteExcludingSelectedWebsite = this.allwebsites.filter((name:any)=>{
                        return name != this.mainSystem.url
                    })
				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        },
    },
    async mounted() {
        this.visibleTabs = [...this.carouselHead];
        
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.updateButtonStates(); 

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});
    },
    async created() {
        this.routesArray = [
            { name: "Systems", routeName: "systems" },
            { name: 'Control Mapping', routeName: "" },
        ];

        this.dataLoading = true;

        await this.getallUsers();
        await this.getAllVendors()
        await this.getAllSystemsRoot()
        this.dummySystem = {...this.mainSystem};
        
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        
        savedTabKey = parseInt(savedTabKey)
		if (savedTabKey) {
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Process', name: 'Process', id: 0 },
                this.selectedIndex = 0;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Process', name: 'Process', id: 0 },
			this.selectedIndex = 0;
            await this.toggleTabs(this.selectedTabObj);
		}

        this.dataLoading = false;
        
        const windowWidth = window.innerWidth
        if(windowWidth <= 1150){
            this.screenReduced = true
        } else {
            this.screenReduced = false
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab');
    }
});
</script>
